import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import { baseConfig } from "../config";
import { baseOverrides } from "../overrides";

import bg from "./home-background.jpg";
import logo from "./logo.png";

const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_NORMAL = 500;
const FONT_MEDIUM = 600;
const FONT_BOLD = 600;

const config = merge({}, cloneDeep(baseConfig), {
  name: "Oakland County Michigan",
  language: {
    "en-us": {
      onboardingTitle:
        "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
    },
  },
  tiers: {
    default: "base",
    available: ["base", "pearl"],
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  logo: {
    imageSrc: logo,
    alt: "Oakland County Michigan Logo",
    height: 64,
    width: 154,
    poweredBy: true,
  },
  splash: {
    imageSrc: bg,
  },
  //shadows:[],
  typography: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightNormal: FONT_NORMAL,
    fontWeightMedium: FONT_MEDIUM,
    fontWeightBold: FONT_BOLD,
    h1: {
      fontWeight: FONT_REGULAR,
      fontSize: "40px",
    },
    h2: {
      fontWeight: FONT_REGULAR,
      fontSize: "24px",
    },
    h3: {
      fontWeight: FONT_REGULAR,
      fontSize: "22px",
    },
    h4: {
      fontWeight: FONT_MEDIUM,
      fontSize: "20px",
    },
    h5: {
      fontWeight: FONT_MEDIUM,
      fontSize: "18px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "18px",
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#007A52",
      light: "#339474",
    },
    secondary: {
      main: "#007ab8",
    },
    accent: {
      main: "#505050",
    },
    success: {
      main: "#0072CE",
    },
    warning: {
      main: "#8DC8E8",
    },
  },
});

export default {
  ...overrides,
  config,
};
