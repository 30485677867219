import React, { useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";

import { Room } from "@library/domain/home";

import assets from "../assets";

const {
  LIVING_ROOM,
  KITCHEN,
  DINING_ROOM,
  BATHROOM,
  OFFICE,
  BEDROOM,
  DEN,
  MEDIA_ROOM,
  FOYER,
  LAUNDRY_ROOM,
} = assets.roomType;

const ROOM_TYPE_ICONS: Record<string, React.FC> = {
  LIVING_ROOM,
  KITCHEN,
  DINING_ROOM,
  BATHROOM,
  OFFICE,
  BEDROOM,
  DEN,
  MEDIA_ROOM,
  FOYER,
  LAUNDRY_ROOM,
};

export const FloorPlan = (props: WidgetProps) => {
  const [rooms, setRooms] = useState<Partial<Room>[]>(props.value);
  const theme = useTheme();
  const roomCounts = {} as Record<string, number>;

  rooms.forEach((room: Partial<Room>) => {
    const type = room.type;
    if (!type) return;
    if (!roomCounts[type]) roomCounts[type] = 0;
    roomCounts[type] += 1;
  });
  const remainingRooms = Object.keys(ROOM_TYPE_ICONS).filter((r) => {
    if (Object.keys(roomCounts).includes(r)) return false;
    return true;
  });

  return (
    <Stack justifyContent="center" alignItems="center">
      <Stack sx={{ maxWidth: "700px" }}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          {Object.keys(roomCounts).map((type) => {
            const count = roomCounts[type as keyof typeof roomCounts];
            if (!type) return;
            const Icon = ROOM_TYPE_ICONS[type];
            return (
              <Stack bgcolor="white" borderRadius="8px" width="100%">
                <Stack direction="row" px={1} p={2}>
                  <Stack
                    direction={["column", "row"]}
                    flex={1}
                    justifyItems="center"
                    alignItems="center"
                  >
                    <Stack
                      justifyContent="flex-start"
                      width={46}
                      height={46}
                      alignItems="center"
                      justifyItems="center"
                      sx={{ marginRight: "10px" }}
                    >
                      {<Icon />}
                    </Stack>
                    <Typography
                      fontSize={["16px", "24px"]}
                      style={{
                        fontWeight: theme.typography.fontWeightLight,
                      }}
                    >
                      {theme.t(`labels.rooms.type.enum.${type}`) || type}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {count > 0 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          borderRadius: 20,
                          padding: 0,
                          marginRight: "20px",
                          width: "43px",
                          minWidth: "0px",
                          fontSize: "1.5rem",
                        }}
                        onClick={() => {
                          let newRooms = rooms
                            .slice()
                            .reverse() as Partial<Room>[];
                          for (let i = 0; i < newRooms.length; i++) {
                            if (newRooms[i].type === type) {
                              newRooms.splice(i, 1);
                              break;
                            }
                          }
                          newRooms = newRooms.reverse();
                          setRooms(newRooms);
                          props.onChange(newRooms);
                        }}
                      >
                        -
                      </Button>
                    )}
                    <Typography
                      fontSize="1.5rem"
                      fontWeight="bold"
                      color="secondary"
                    >
                      {count}
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        borderRadius: 20,
                        padding: 0,
                        marginLeft: "20px",
                        width: "43px",
                        minWidth: "0px",
                        fontSize: "1.5rem",
                      }}
                      onClick={() => {
                        const newRooms = rooms.slice() as Partial<Room>[];
                        newRooms.push({
                          type,
                          name: `${
                            theme.t(`labels.rooms.type.enum.${type}`) || type
                          } ${roomCounts[type] ? roomCounts[type] + 1 : 1}`,
                          isCurrentlyHeated: true,
                          isHeatComfortIssue: false,
                          isCurrentlyAirConditioned: true,
                          isAirConditionComfortIssue: false,
                        });
                        setRooms(newRooms);
                        props.onChange(newRooms);
                      }}
                    >
                      +
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Stack alignItems="center" margin={2}>
          <Typography fontSize={["1.3rem", "1.5rem", "1.8rem"]}>
            Add Rooms
          </Typography>
        </Stack>
        <Stack justifyContent="center" alignItems="center" width="100%">
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {remainingRooms.map((type) => {
              const Icon = ROOM_TYPE_ICONS[type] as React.FC<{
                stroke: string;
              }>;
              return (
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    border={`1px dashed ${theme.palette.secondary.main}`}
                    borderRadius="8px"
                  >
                    <Stack direction="row" px={1} p={2}>
                      <Stack
                        direction={["column", "row"]}
                        flex={1}
                        justifyItems="center"
                        alignItems="center"
                      >
                        <Stack
                          justifyContent="flex-start"
                          width={46}
                          height={46}
                          alignItems="center"
                          justifyItems="center"
                          sx={{ marginRight: "10px" }}
                        >
                          {<Icon stroke={theme.palette.secondary.main} />}
                        </Stack>
                        <Typography
                          fontSize={["1.0rem", "1.2rem"]}
                          color="secondary"
                          style={{
                            fontWeight: 400,
                          }}
                        >
                          {theme.t(`labels.rooms.type.enum.${type}`)}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          data-cy={type}
                          sx={{
                            borderRadius: 20,
                            padding: 0,
                            marginLeft: "20px",
                            width: "43px",
                            minWidth: "0px",
                            fontSize: "1.5rem",
                          }}
                          onClick={() => {
                            const newRooms = rooms.slice() as Partial<Room>[];
                            newRooms.push({
                              type,
                              name:
                                theme.t(`labels.rooms.type.enum.${type}`) ||
                                type,
                              isCurrentlyHeated: true,
                              isHeatComfortIssue: false,
                              isCurrentlyAirConditioned: true,
                              isAirConditionComfortIssue: false,
                            });
                            setRooms(newRooms);
                            props.onChange(newRooms);
                          }}
                        >
                          +
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FloorPlan;
