import React, { useMemo, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";
import LoadCalculationTable from "@library/components/LoadCalculationTable";
import { FloorData } from "@library/domain/home";
import {
  calculateRoomBTU,
  calculateSourceTotals,
  calculateTotalBTU,
} from "@library/common";

// TODO: will use these with real data once estimate is generated
// import {
//   calculateRoomBTU,
//   calculateTotalBTU,
//   calculateSourceTotals,
// } from "@library/common";

const baseLanguageLocation = "concierge.PackageDetails.LoadCalculationContent";

export const LoadCalculationContent: React.FC<SectionContentProps> = ({
  theme,
  package: pkg,
}) => {
  const [viewing, setViewing] = useState<"room" | "source">("room");

  const { floorData, totalBTU } = useMemo(() => {
    const loadCalculations = pkg?.polyvectorPayload?.loadCalculations;
    const floorData = loadCalculations
      ? calculateRoomBTU(loadCalculations)
      : [];
    const totalBTU = loadCalculations
      ? calculateTotalBTU(loadCalculations)
      : { heating: 0, cooling: 0 };
    return { floorData, totalBTU };
  }, [pkg?.polyvectorPayload?.loadCalculations]);

  const sourceTotals = useMemo(
    () => calculateSourceTotals(pkg?.polyvectorPayload?.loadCalculations || []),
    [pkg?.polyvectorPayload?.loadCalculations]
  );

  return (
    <Root>
      <Box>
        <Typography
          className={classes.sectionContentDescription}
          sx={{ mb: 2 }}
        >
          {theme?.t(`${baseLanguageLocation}.description`)}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LoadCalculationTable
              floorData={floorData as FloorData[]}
              totalBTU={totalBTU}
              viewing={viewing}
              sourceTotals={sourceTotals}
              onViewingChange={(newView) => setViewing(newView)}
            />
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
