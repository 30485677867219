import { styled } from "@mui/material";

const PREFIX = "PlaceholderImage";

export const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    width: "100%",
    backgroundColor: theme.palette.divider,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.icon}`]: {
    color: "white",
  },
}));
