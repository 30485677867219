import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";

export const FAQContent: React.FC<SectionContentProps> = () => (
  <Root>
    <Box>
      <Typography className={classes.sectionContentDescription} sx={{ mb: 2 }}>
        Do we need this for frequently asked questions? I'm not too sure but I
        put it here in case...
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>TBD</Typography>
        </Grid>
      </Grid>
    </Box>
  </Root>
);
