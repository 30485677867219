/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";
import queryString from "query-string";
import { withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useLocation, useSearchParams } from "react-router-dom";

import widgets from "@library/components/form/widgets";

import {
  getNextSlide,
  getPreviousSlide,
  getSpecificSlide,
  getSlide,
  SlideSchema,
} from "./functions";
import { SettingsContext } from "@library/settings/provider";
import SkipModal from "./components/SkipModal";
import isFunction from "lodash/isFunction";
// import ProgressBar from "./components/ProgressBar";

// import PreFetchImages from "@library/components/form/assets/PreFetchImages";
import FieldErrorTemplate from "@library/components/form/templates/FieldErrorTemplate";
import ErrorListTemplate from "@library/components/form/templates/ErrorListTemplate";
import Progress from "./components/Progress";

const schema = (): RJSFSchema => {
  return {
    type: "string",
  };
};

const uiSchema = (): UiSchema => {
  return {
    "ui:autocomplete": "off",
  };
};

interface SherpaContext {
  payload: Partial<any>;
  setPayload: (value: Partial<any>) => void;
  slideIndex: number;
  setSlideIndex: (value: number) => void;
}

const SherpaContext = createContext<SherpaContext>({
  payload: {},
  setPayload: () => {},
  slideIndex: 0,
  setSlideIndex: () => {},
});

const NoComponent = () => {
  return <></>;
};

export const Sherpa = ({
  slides,
  initialPayload = {},
  initialSlideIndex = 0,
  data = {},
  onProgress = () => {
    return Promise.resolve();
  },
  onSubmit = () => {
    return Promise.resolve();
  },
  storageKey,
  readonly = false,
  progressSx,
}: {
  slides: SlideSchema[];
  initialPayload?: any;
  initialSlideIndex?: number;
  data?: any;
  onProgress: ({
    payload,
    slideIndex,
    slideKey,
  }: {
    payload: any;
    slideIndex?: number;
    slideKey?: string;
  }) => Promise<any>;
  storageKey?: string;
  onSubmit: ({ payload }: { payload: any }) => Promise<any>;
  readonly?: boolean;
  progressSx?: any;
}) => {
  const theme = useTheme();
  const { user, setUser, setIsAuthenticated } = useContext(SettingsContext);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [payload, setPayload] = useState<any>({
    ...initialPayload,
  });

  const [skipModalOpen, setSkipModalOpen] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(initialSlideIndex ?? 0);
  const [nextSlideIndex, setNextSlideIndex] = useState<number>(slideIndex);
  const [slideDirection, setSlideDirection] = useState<number>(0);
  const [slide, setSlide] = useState<SlideSchema>({
    key: "",
    schema,
    uiSchema,
  });
  const [slideState, setSlideState] = useState<Record<string, any>>({}); // Useful current slide state
  const [error, setError] = useState<string | ReactElement>("");
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const Form = withTheme(Theme);
  const formRef = useRef(null);

  const Header = slide.Header ?? NoComponent;
  const Title = slide.Title ?? NoComponent;
  const Description = slide.Description ?? NoComponent;
  const Footer = slide.Footer ?? NoComponent;
  const Callout = slide.Callout ?? NoComponent;
  const fetchOnLoad = slide.fetchOnLoad;

  const handlePageChange = (_slideIndex: number) => {
    setSlideIndex(_slideIndex);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (fetchOnLoad) {
      fetchOnLoad({ slideState, payload, theme }).then((newSlideState) => {
        setSlideState(newSlideState);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnLoad]);

  useEffect(() => {
    getSlide({ slides, slideIndex }).then((nextSlide) => {
      setSlide(nextSlide);
    });
    getNextSlide({
      slides,
      index: slideIndex,
      payload,
      setPayload: () => {},
      setError: () => {},
      user,
      setUser: () => {},
      setIsAuthenticated: () => {},
      executeOnNext: false,
      setLoading: () => {},
      theme,
      readonly,
    }).then((nextSlideIndex) => {
      if (nextSlideIndex !== slideIndex) {
        setError("");
      }
      setNextSlideIndex(nextSlideIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex]);

  useEffect(() => {
    const qs = queryString.parse(location.search) ?? {};
    if (qs.s) {
      const slideIndexParam = parseInt(String(qs.s), 10);
      if (slideIndexParam > -1) {
        handlePageChange(slideIndexParam);
        if (readonly) return;
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        setSearchParams({ s: "" });
      }
    }
  }, [slideIndex, search, setSearchParams, readonly]);

  const submitForm = useCallback(() => {
    setLoading(true);
    if (storageKey) {
      const slideKey = slides[slideIndex].key;
      localStorage.setItem(
        storageKey,
        JSON.stringify({ payload, slideIndex, slideKey }, null, 4)
      );
    }
    onSubmit({
      payload,
    })
      .then(() => {
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [onSubmit, payload, slides, slideIndex, storageKey]);

  const submitProgress = ({
    payload,
    slideIndex,
    slideKey,
  }: {
    payload: any;
    slideIndex: number;
    slideKey: string;
  }) => {
    if (storageKey) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({ payload, slideIndex, slideKey }, null, 4)
      );
    }
    if (onProgress) {
      onProgress({
        payload,
        slideIndex,
        slideKey,
      }).catch((error: string) => {
        setError(error);
      });
    }
  };

  useEffect(() => {
    if (isFunction(slide.visible)) {
      const isVisible = slide.visible?.({ payload, theme });
      if (!isVisible && nextSlideIndex === -1) {
        setLoading(true);
        submitForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSlideIndex]);

  useEffect(() => {
    if (slideDirection !== 0) {
      setShow(false);
    }
  }, [slideDirection]);

  useEffect(() => {
    if (show) return;

    setTimeout(() => {
      if (slideDirection === 1) {
        getNextSlide({
          slides,
          index: slideIndex,
          payload,
          setPayload,
          setError,
          user,
          setUser,
          setIsAuthenticated,
          executeOnNext: true,
          setLoading,
          theme,
          readonly,
        }).then(async (newSlideIndex) => {
          setError("");
          if (newSlideIndex === -1) {
            submitForm();
          } else {
            submitProgress({
              payload,
              slideIndex: newSlideIndex,
              slideKey: slides[newSlideIndex].key,
            });
            setSlideIndex(newSlideIndex);
            window.scrollTo(0, 0);
          }
        });
      }
      if (slideDirection === -1) {
        getPreviousSlide({
          slides,
          index: slideIndex,
          payload,
          theme,
        }).then(async (newSlideIndex) => {
          setError("");
          submitProgress({
            payload,
            slideIndex: newSlideIndex,
            slideKey: slides[newSlideIndex].key,
          });
          setSlideIndex(newSlideIndex);
          window.scrollTo(0, 0);
        });
      }
      setShow(true);
      setTimeout(() => {
        setSlideDirection(0);
      }, 200);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideDirection, show, payload]);

  const handleSkip = (toSkip: boolean) => {
    setSkipModalOpen(false);
    if (toSkip) {
      setSlideDirection(1);
    }
  };

  const background = (slide.background && slide.background(theme)) || "none";

  let direction = "left" as keyof { left: true; right: true };
  if (show) {
    if (slideDirection === -1) {
      direction = "right";
    } else if (slideDirection === 1) {
      direction = "left";
    }
  } else {
    if (slideDirection === -1) {
      direction = "left";
    } else if (slideDirection === 1) {
      direction = "right";
    }
  }

  return (
    <SherpaContext.Provider
      value={{ payload, setPayload, slideIndex, setSlideIndex }}
    >
      <div style={{ overflow: "hidden" }}>
        {isLoading && (
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              zIndex: 9999,
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              background: `rgba(255,255,255,${
                nextSlideIndex === -1 ? 1.0 : 0.4
              })`,
            }}
          >
            <Stack
              sx={{
                top: "calc(50% - 20px)",
                left: "calc(50% - 20px)",
                position: "relative",
              }}
            >
              <CircularProgress />
            </Stack>
          </Box>
        )}
        {!readonly && slide.progress !== false && (
          <>
            <Progress
              slides={slides}
              slide={slide}
              slideIndex={slideIndex}
              onChange={handlePageChange}
              sx={progressSx}
            />
          </>
        )}
        <Slide
          in={Boolean(show && direction.length)}
          direction={direction}
          easing={{
            enter:
              slide.animation !== false
                ? theme.transitions.easing.easeOut
                : "0.0s",
            exit:
              slide.animation !== false
                ? theme.transitions.easing.sharp
                : "0.0s",
          }}
        >
          <Box
            px={[1, 2, 8]}
            pb={8}
            pt={3}
            overflow="auto"
            flex={1}
            flexGrow={1}
            sx={{
              background,
              backgroundSize: "cover",
              minHeight: `calc(100vh - ${slide.progress !== false ? 128 : 64}px)`,
            }}
          >
            <Stack width="100%" alignItems="center">
              {Title && (
                <Title
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={data}
                  formRef={formRef}
                />
              )}
              {Description && (
                <Description
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={data}
                  formRef={formRef}
                />
              )}
              {Header && (
                <Header
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={data}
                  formRef={formRef}
                />
              )}
            </Stack>
            <Stack sx={{ paddingBottom: "30px" }}>
              <Form
                ref={formRef}
                schema={{
                  ...slide.schema(payload, slideState),
                }}
                uiSchema={{
                  ...uiSchema,
                  ...slide.uiSchema(payload, slideState),
                }}
                widgets={widgets}
                formData={payload}
                validator={validator}
                formContext={{
                  setPayload,
                  payload,
                  setSlideDirection,
                  slideState,
                  setSlideState,
                  slideIndex,
                  setError,
                }}
                onSubmit={({ formData }) => {
                  if (slide.customSubmit) {
                    return;
                  }
                  setPayload(formData);
                  setSlideDirection(1);
                  setError("");
                }}
                templates={{ ErrorListTemplate, FieldErrorTemplate }}
                transformErrors={(errors) => {
                  return errors.map((error) => {
                    if (error.name === "required") {
                      // error.message = <Alert severity="error" sx={{ width: "100%" }}>Required</Alert>;
                      error.message = "Required";
                    }
                    return error;
                  });
                }}
              >
                {Callout && (
                  <Callout
                    stepName={slide.key}
                    slideIndex={slideIndex}
                    payload={payload}
                    setPayload={setPayload}
                    data={data}
                    formRef={formRef}
                  />
                )}
                {error && (
                  <Stack
                    spacing={2}
                    m={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Alert sx={{ margin: 2 }} severity="error">
                      {error}
                    </Alert>
                  </Stack>
                )}
              </Form>
            </Stack>
          </Box>
        </Slide>
        {!slide.customNavigation && (
          <Stack
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              boxShadow: "0px 0px 20px 0px #CCC",
              height: "75px",
              backgroundColor: theme.palette.primary.contrastText,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              mt={2}
              justifyContent="center"
              alignItems="center"
            >
              {slideIndex > 0 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setSlideDirection(-1);
                  }}
                  sx={{
                    width: "150px",
                    color: slide.dark
                      ? theme.palette.primary.contrastText
                      : undefined,
                    borderColor: slide.dark ? "transparent" : undefined,
                    backgroundColor: slide.dark
                      ? "rgba(255,255,255,.2)"
                      : undefined,
                  }}
                >
                  {slide.backTitle ? slide.backTitle : "Back"}
                </Button>
              )}
              {slide.nextTitle !== "" && (
                <Button
                  variant="contained"
                  type="submit"
                  data-cy="submit"
                  data-key={slide.key}
                  color="secondary"
                  sx={{ width: "150px" }}
                  onClick={() => {
                    const form = formRef.current || { submit: () => {} };
                    if (form && form.submit) form.submit();
                  }}
                >
                  {nextSlideIndex !== -1
                    ? slide.nextTitle
                      ? slide.nextTitle
                      : "Next"
                    : "Submit"}
                </Button>
              )}
            </Stack>
            {slide.canSkip && (
              <Stack
                width="100%"
                justifyContent="center"
                direction="row"
                sx={{ marginTop: "10px" }}
              >
                <Button
                  variant="text"
                  onClick={() => setSkipModalOpen(true)}
                  color="secondary"
                  sx={{ textTransform: "none" }}
                >
                  Skip this step
                </Button>
              </Stack>
            )}
          </Stack>
        )}
        <SkipModal
          open={skipModalOpen}
          onClose={(toSkip: boolean) => handleSkip(toSkip)}
        />
        {Footer && (
          <Footer
            stepName={slide.key}
            setPayload={setPayload}
            formRef={formRef}
            payload={payload}
            slideIndex={slideIndex}
            slideState={slideState}
            setSlideState={setSlideState}
            setError={setError}
            error={error}
            data={data}
            setSlideDirection={setSlideDirection}
            setSlideIndex={setSlideIndex}
            getSpecificSlide={getSpecificSlide}
          />
        )}
      </div>
      {/* <PreFetchImages /> */}
    </SherpaContext.Provider>
  );
};

export default Sherpa;
