import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Checkbox,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { Room } from "@library/domain/home";

import cloneDeep from "lodash/cloneDeep";

export const FloorPlan = (props: WidgetProps) => {
  const theme = useTheme();
  const {
    currently,
    issue,
    info,
  }: { currently: keyof Room; issue: keyof Room; info: keyof Room } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.uiSchema as unknown as any;

  const rooms = cloneDeep(props.value);
  const shownRooms = rooms.filter((room: Partial<Room>) => {
    if (
      ["MASTER_BATHROOM", "BATHROOM", "FOYER", "LAUNDRY_ROOM"].includes(
        room.type ?? ""
      )
    )
      return false;
    return true;
  });

  return (
    <Stack alignItems="center">
      <Stack
        spacing={1}
        sx={{ width: ["100%", "90%", "75%"] }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 0,
              paddingBottom: 0,
              borderRadius: 2,
            }}
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography fontWeight="bold">Room</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="bold" style={{ textAlign: "center" }}>
                  {theme.t(`labels.${props.name}.${currently}.label`)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="bold" style={{ textAlign: "center" }}>
                  {theme.t(`labels.${props.name}.${issue}.label`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {shownRooms.map((room: any) => {
          return (
            <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "white",
                  paddingLeft: 3,
                  paddingRight: 3,
                  paddingTop: 1,
                  paddingBottom: 1,
                  borderRadius: 2,
                }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={4}>
                    {room.name}
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Checkbox
                      onChange={(event) => {
                        room[currently] = event.target.checked;
                        props.onChange(rooms);
                      }}
                      sx={{
                        svg: {
                          fill: theme.palette.secondary.main,
                        },
                      }}
                      checked={room[currently]}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Checkbox
                      onChange={(event) => {
                        room[issue] = event.target.checked;
                        props.onChange(rooms);
                      }}
                      sx={{
                        svg: {
                          fill: theme.palette.secondary.main,
                        },
                      }}
                      checked={room[issue]}
                    />
                  </Grid>
                  {room[issue as keyof typeof room] && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      mt={1}
                      mb={1}
                    >
                      <TextField
                        label={theme.t(`labels.${props.name}.${info}.label`)}
                        variant="outlined"
                        defaultValue={room[info]}
                        sx={{ width: "100%" }}
                        onChange={(event) => {
                          room[info] = event?.target.value;
                          props.onChange(rooms);
                        }}
                        multiline
                      />
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FloorPlan;
