import { styled } from "@mui/material";

const PREFIX = "JobDetailsPageView";

export const classes = {
  heatText: `${PREFIX}-heatText`,
  coolText: `${PREFIX}-coolText`,
  leftHeatHeaderCell: `${PREFIX}-leftHeatHeaderCell`,
  rightHeatHeaderCell: `${PREFIX}-rightHeatHeaderCell`,
  leftHeatTableCell: `${PREFIX}-leftHeatTableCell`,
  lastHeatRoomCell: `${PREFIX}-lastHeatRoomCell`,
  leftCoolHeaderCell: `${PREFIX}-leftCoolHeaderCell`,
  leftCoolTableCell: `${PREFIX}-leftCoolTableCell`,
  rightCoolHeaderCell: `${PREFIX}-rightCoolHeaderCell`,
  lastCoolRoomCell: `${PREFIX}-lastCoolRoomCell`,
  leftCoolTotalCell: `${PREFIX}-leftCoolTotalCell`,
  grayRow: `${PREFIX}-grayRow`,
  firstColumn: `${PREFIX}-firstColumn`,
  heatBackground: `${PREFIX}-heatBackground`,
  coolBackground: `${PREFIX}-coolBackground`,
  borderlessCell: `${PREFIX}-borderlessCell`,
  tableHeaderContainer: `${PREFIX}-tableHeaderContainer`,
  tableHeaderInfoContainer: `${PREFIX}-tableHeaderInfoContainer`,
  tableHeaderInfoColumn: `${PREFIX}-tableHeaderInfoColumn`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.heatText}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.coolText}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${classes.leftHeatHeaderCell}`]: {
    borderLeft: `1px dotted ${theme.palette.error.main}`,
    borderTop: `1px solid ${theme.palette.error.main}`,
  },
  [`& .${classes.rightHeatHeaderCell}`]: {
    borderTop: `1px solid ${theme.palette.error.main}`,
  },
  [`& .${classes.leftHeatTableCell}`]: {
    borderLeft: `1px dotted ${theme.palette.error.main}`,
  },
  [`& .${classes.lastHeatRoomCell}`]: {
    borderBottom: `1px solid ${theme.palette.error.main}`,
  },
  [`& .${classes.leftCoolHeaderCell}`]: {
    borderLeft: `1px dotted ${theme.palette.secondary.main}`,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  [`& .${classes.rightCoolHeaderCell}`]: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  [`& .${classes.leftCoolTableCell}`]: {
    borderLeft: `1px dotted ${theme.palette.secondary.main}`,
  },
  [`& .${classes.lastCoolRoomCell}`]: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  [`& .${classes.grayRow}`]: {
    backgroundColor: "#EFEFEF",
  },
  [`& .${classes.firstColumn}`]: {
    paddingLeft: `${theme.spacing(2)} !important`,
  },
  [`& .${classes.heatBackground}`]: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  [`& .${classes.coolBackground}`]: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  [`& .${classes.borderlessCell}`]: {
    border: "none",
    fontSize: 24,
    "& svg": {
      marginBottom: -4, // flex display to center here breaks default cell styling
    },
  },
  [`& .${classes.tableHeaderContainer}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.tableHeaderInfoContainer}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },
  [`& .${classes.tableHeaderInfoColumn}`]: {
    textAlign: "right",
  },
}));
