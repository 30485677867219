import React from "react";
import { Typography, Box, Grid, Card, Divider } from "@mui/material";

import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import { ElectricBike } from "@mui/icons-material";
import michiganSavesLogo from "@library/assets/michiganSavesLogo.png";
import { formatCurrency, formatPricingData } from "@library/common";
// import PlaceholderImage from "@library/components/PlaceholderImage";
const happyCustomer = "../../public/assets/happyCustomer.png";

const baseLanguageLocation = "concierge.PackageDetails.SummaryContent";

export const SummaryContent: React.FC<SectionContentProps> = ({
  package: pkg,
  theme,
}) => {
  const formattedSections = formatPricingData(pkg?.polyvectorPayload);

  return (
    <Root>
      <Box>
        <Typography className={classes.sectionContentDescription}>
          {pkg?.data?.recommendationExplanation}
        </Typography>
        <Typography
          variant={"h5"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {theme?.t(`${baseLanguageLocation}.subtitle`)}:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              <Card className={classes.smallCard}>
                <Grid container>
                  <Grid item xs={3} className={classes.smallCardIconContainer}>
                    <ElectricBike sx={{ fontSize: 30 }} />{" "}
                    {/* TODO: figure out custom icons from designs... */}
                  </Grid>
                  <Grid item xs={9} className={classes.smallCardTextContainer}>
                    <Typography className={classes.boldText}>
                      Heat Pump
                    </Typography>
                    <Typography variant="body2">
                      A heat pump efficiently heats and cools your home using
                      electricity - sort of like a combined furnace and air
                      conditioner
                    </Typography>
                    <a href="#">
                      {theme?.t(`${baseLanguageLocation}.learnMore`)}
                    </a>
                  </Grid>
                </Grid>
              </Card>
              <Card className={classes.smallCard}>
                <Grid container>
                  <Grid item xs={3} className={classes.smallCardIconContainer}>
                    <ElectricBike sx={{ fontSize: 30 }} />{" "}
                    {/* TODO: figure out custom icons from designs... */}
                  </Grid>
                  <Grid item xs={9} className={classes.smallCardTextContainer}>
                    <Typography className={classes.boldText}>Ducted</Typography>
                    <Typography variant="body2">
                      This system will utilize your existing ductwork, assuming
                      it's usable. This reduces the cost of your new system.
                    </Typography>
                    <a href="#">
                      {theme?.t(`${baseLanguageLocation}.learnMore`)}
                    </a>
                  </Grid>
                </Grid>
              </Card>
              <Card className={classes.smallCard}>
                <Grid container>
                  <Grid item xs={3} className={classes.smallCardIconContainer}>
                    <ElectricBike sx={{ fontSize: 30 }} />{" "}
                    {/* TODO: figure out custom icons from designs... */}
                  </Grid>
                  <Grid item xs={9} className={classes.smallCardTextContainer}>
                    <Typography className={classes.boldText}>
                      Dual-Fuel
                    </Typography>
                    <Typography variant="body2">
                      This system runs on electricity (heat pump) and gas
                      (backup) - whichever is cheaper. This reduces your utility
                      bill.
                    </Typography>
                    <a href="#">
                      {theme?.t(`${baseLanguageLocation}.learnMore`)}
                    </a>
                  </Grid>
                </Grid>
              </Card>
              <Card className={classes.smallCard}>
                <Grid container>
                  <Grid item xs={3} className={classes.smallCardIconContainer}>
                    <ElectricBike sx={{ fontSize: 30 }} />{" "}
                    {/* TODO: figure out custom icons from designs... */}
                  </Grid>
                  <Grid item xs={9} className={classes.smallCardTextContainer}>
                    <Typography className={classes.boldText}>
                      Weatherization
                    </Typography>
                    <Typography variant="body2">
                      Reducing energy waste - via insulation, air sealing, and
                      ductwork - improves comfort and reduces bills.
                    </Typography>
                    <a href="#">
                      {theme?.t(`${baseLanguageLocation}.learnMore`)}
                    </a>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.largeCard}>
              <Typography
                variant={"h5"}
                className={classes.largeCardTitle}
                sx={{ ml: 2, mb: 2 }}
              >
                {theme?.t(`${baseLanguageLocation}.summaryQuote`)}
              </Typography>
              <img src={happyCustomer} style={{ width: "100%" }} />
              {/* <PlaceholderImage imageHeight={200} sx={{ mb: 1 }} /> */}
              {formattedSections.map((section, index) => (
                <React.Fragment key={`${section.title}-${index}`}>
                  <Box
                    className={classes.contentLineItem}
                    sx={{ py: 1, px: 2 }}
                  >
                    <Typography className={classes.boldText}>
                      {section.title}
                    </Typography>
                    <Typography>${section.total.toLocaleString()}</Typography>
                  </Box>
                  <ul className={classes.list}>
                    {section.items.map((item, idx) => (
                      <li key={`${item.id}-${idx}`}>
                        <Typography variant="body2">{item.name}</Typography>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))}
              <Divider sx={{ mt: 2 }} />
              <Box className={classes.contentLineItem} sx={{ pt: 2, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.cost`)}
                </Typography>
                <Typography>
                  {formatCurrency(pkg?.polyvectorPayload?.installedCostTotal)}
                </Typography>
              </Box>
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography>
                  {theme?.t(`${baseLanguageLocation}.rebates`)}
                </Typography>
                <Typography>
                  {formatCurrency(pkg?.polyvectorPayload?.rebatesTotal)}
                </Typography>
              </Box>
              <Box className={classes.contentLineItem} sx={{ pt: 1, px: 2 }}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.costAfterRebates`)}
                </Typography>
                <Typography className={classes.boldText}>
                  {" "}
                  {formatCurrency(
                    (pkg?.polyvectorPayload?.installedCostTotal || 0) -
                      (pkg?.polyvectorPayload?.rebatesTotal || 0)
                  )}
                </Typography>
              </Box>
              <Box className={classes.financeBoxContainer}>
                <img src={michiganSavesLogo} />
                <Typography>
                  Pay as little as $83 per month with{" "}
                  <a href="#">flexible financing</a> from Michigan Saves.
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
