import { useTheme } from "@mui/material";
import React from "react";

export const Heating = () => {
  const theme = useTheme();
  return (
    <svg
      width="200"
      height="160"
      viewBox="0 0 200 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          width="260"
          height="160"
          style={{ fill: theme.palette.primary.main }}
        />
        <path
          d="M103.697 37.8037L114.861 34.6396H175.905V133.274H114.957L103.697 124.894L103.697 37.8037Z"
          style={{ stroke: theme.palette.secondary.contrastText }}
        />
        <path
          d="M122.525 42.302H131.624"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M122.525 45.3835H131.624"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M122.525 48.4653H131.624"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M122.525 51.5466H131.624"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M122.525 54.6282H131.624"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M134.853 42.302H143.951"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M134.853 45.3835H143.951"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M134.853 48.4653H143.951"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M134.853 51.5466H143.951"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M134.853 54.6284H143.951"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M147.178 42.3018H156.276"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M147.178 45.3833H156.276"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M147.178 48.4651H156.276"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M147.178 51.5466H156.276"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M147.178 54.6284H156.276"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M159.504 42.3018H168.603"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M159.504 45.3833H168.603"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M159.504 48.4651H168.603"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M159.504 51.5466H168.603"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M159.504 54.6284H168.603"
          style={{ stroke: theme.palette.secondary.contrastText }}
          strokeLinecap="round"
        />
        <path
          d="M110.233 -4.6712V35.5098M115.236 -5.45825V17.4743M115.236 17.4743L123.262 27.5057M115.236 17.4743H159.611M123.262 27.5057V34.5278M123.262 27.5057L167.637 27.5057M159.611 -5.45825V17.4743M159.611 17.4743L167.637 27.5057M167.637 27.5057V34.5278"
          style={{ stroke: theme.palette.secondary.contrastText }}
        />
        <path
          d="M104.381 89.4253V9.48438L83.5938 15.5618V95.7778C83.5938 121.247 102.651 119.631 110.498 119.818V95.7778C105.666 95.7778 104.406 91.5428 104.381 89.4253Z"
          style={{ stroke: theme.palette.primary.main }}
        />
        <path
          d="M104.381 -4.8457V89.4254C104.406 91.5429 105.666 95.7779 110.498 95.7779V119.818C97.6894 119.818 83.5938 114.498 83.5938 95.7779V-2.76824"
          style={{ stroke: theme.palette.secondary.contrastText }}
        />
      </g>
    </svg>
  );
};

export default Heating;
