import { styled } from "@mui/material";

const PREFIX = "ErrorToast";

export const classes = {
  errorAlert: `${PREFIX}-errorAlert`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.errorAlert}`]: {
    width: "100%",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "& .MuiAlert-icon": {
      color: theme.palette.common.white,
    },
  },
}));
