import { ReactElement } from "react";
import { Theme } from "@mui/material";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { User } from "@supabase/supabase-js";

export interface SlideComponent {
  stepName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Partial<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPayload: (payload: Partial<any>) => void;
  setSlideDirection?: (direction: number) => void;
  setSlideIndex?: (index: number) => void;
  getSpecificSlide?: ({
    index,
    keyIndex,
    numIndex,
  }: {
    index: number;
    keyIndex?: string;
    numIndex?: number;
  }) => Promise<number>;
  slideIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSlideState?: (val: any) => void;
  formRef: React.RefObject<React.Component>;
  setError?: (val: string | ReactElement) => void;
  error?: string | ReactElement;
  setLoading?: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export interface OnNextReturn {
  error?: string | ReactElement;
}

export interface SlideSchema {
  key: string;
  sectionLabel?: string;
  stepLabel?: string;
  progress?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: (payload: Partial<any>, slideState?: any) => RJSFSchema;
  uiSchema: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Partial<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slideState?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formRef?: any
  ) => UiSchema;
  visible?: ({
    payload,
    theme,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Partial<any>;
    theme: Theme;
  }) => boolean;
  onNext?: ({
    index,
    payload,
    setPayload,
    user,
    setUser,
    setIsAuthenticated,
    readonly,
  }: {
    index: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Partial<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPayload: (value: Partial<any>) => void;
    user: Partial<User>;
    setUser: (data: User) => void;
    setIsAuthenticated: (value: boolean) => void;
    readonly?: boolean;
  }) => Promise<OnNextReturn>;
  fetchOnLoad?: ({
    payload,
    slideState,
    theme,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Partial<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slideState: any;
    theme: Theme;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>;
  background?: (theme: Theme) => string;
  canSkip?: boolean;
  customSubmit?: boolean;
  customNavigation?: boolean;
  dark?: boolean;
  animation?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const getSlide = async ({
  slides,
  slideIndex,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slides: any;
  slideIndex: number;
}) => {
  return Promise.resolve(slides[slideIndex]);
};

export const getNextSlide = async ({
  slides,
  index,
  payload,
  setPayload,
  setError,
  user,
  setUser,
  setIsAuthenticated,
  executeOnNext,
  setLoading,
  theme,
  readonly = false,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slides: any;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Partial<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPayload: (payload: Partial<any>) => void;
  setError: (error: string | ReactElement) => void;
  user: Partial<User>;
  setUser: (user: User) => void;
  setIsAuthenticated: (value: boolean) => void;
  executeOnNext: boolean;
  setLoading: (value: boolean) => void;
  theme: Theme;
  readonly?: boolean;
}): Promise<number> => {
  const slide = slides[index];
  if (slide.onNext && executeOnNext) {
    setLoading(true);
    const { error, slideIndex, slideKey } =
      (await slide.onNext({
        index,
        payload,
        setPayload,
        user,
        setUser,
        setIsAuthenticated,
        readonly,
      })) || {};
    if (slideIndex > -1) {
      setLoading(false);
      return slideIndex;
    }
    if (slideKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSlideIndex = slides.findIndex((s: any) => s.key === slideKey);
      if (newSlideIndex >= 0) {
        setLoading(false);
        return newSlideIndex;
      }
    }
    setLoading(false);
    if (error) {
      setError(error);
      return index;
    }
  }

  // @TODO we can have this reach out to the backend to get us the
  // "next slide" that we need, for now we are just adding one
  await Promise.resolve();

  let nextIndex = index + 1;
  let match = false;
  for (let i = nextIndex; i < slides.length; i++) {
    const nextSlide = slides[i];
    if (!nextSlide.visible) {
      match = true;
      nextIndex = i;
      break;
    } else if (nextSlide.visible({ payload, theme })) {
      match = true;
      nextIndex = i;
      break;
    }
  }
  if (!match || nextIndex >= slides.length) return -1; // We are finished!
  return nextIndex; // Next slide
};

export const getPreviousSlide = async ({
  slides,
  index,
  payload,
  theme,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slides: any;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Partial<any>;
  theme: Theme;
}) => {
  if (index === 0) return 0;

  // @TODO we can have this reach out to the backend to get us the
  // "next slide" that we need, for now we are just adding one
  await Promise.resolve();

  let nextIndex = index - 1;
  for (let i = nextIndex; i >= 0; i--) {
    const nextSlide = slides[i];
    if (!nextSlide.visible) {
      nextIndex = i;
      break;
    } else if (nextSlide.visible({ payload, theme })) {
      nextIndex = i;
      break;
    }
  }

  return nextIndex;
};

export const getSpecificSlide = async ({
  slides,
  index,
  keyIndex,
  numIndex,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slides: any;
  index: number;
  keyIndex?: string;
  numIndex?: number;
}) => {
  // @TODO in the future we may ask the backend for a slide
  // by a given id
  await Promise.resolve();

  let nextIndex = index;

  // Go to a specific index
  if (numIndex) {
    if (slides[numIndex]) {
      nextIndex = numIndex;
    } else if (slides[index + 1]) {
      nextIndex = index + 1;
    } else {
      nextIndex = index;
    }
  }

  // Go to a slide by slide key
  if (keyIndex) {
    let match = false;
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].key === keyIndex) {
        nextIndex = i;
        match = true;
        break;
      }
    }
    if (!match) {
      if (slides[index + 1]) {
        nextIndex = index + 1;
      } else {
        nextIndex = index;
      }
    }
  }

  return nextIndex;
};
