import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { Root, classes } from "./styles";

interface PlaceholderImageProps {
  imageHeight?: number;
  iconSize?: number;
  sx?: SxProps<Theme>;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  sx,
  iconSize,
  imageHeight,
}) => {
  return (
    <Root sx={sx}>
      <Box sx={{ height: imageHeight || 300 }} className={classes.container}>
        <ImageIcon sx={{ fontSize: iconSize || 40 }} className={classes.icon} />
      </Box>
    </Root>
  );
};

export default PlaceholderImage;
