import React from "react";

import Sherpa from "@library/components/Sherpa";
import { SlideSchema } from "@library/components/Sherpa/functions";

import { initialOnboardingPayload } from "../../domain/onboarding";
import sortingSlides from "./slides/sorting";

const slides = [...sortingSlides] as unknown as SlideSchema[];

const storageKey = "onboarding";

// @TODO Get the database payload if it exists
// localStorage
const localCache = JSON.parse(localStorage.getItem(storageKey) || "{}");
const localPayload = localCache.payload;
const localSlideKey = localCache.slideKey;
const localSlideIndex =
  slides.findIndex((s) => s.key === localSlideKey) || localCache.slideIndex;

const initialPayload = {
  projectType: "",
  heatingProjectType: "",
  heatingProjectTypeMoreInfo: "",
  ...initialOnboardingPayload,
};

const OnboardingV3PageView = () => {
  return (
    <Sherpa
      slides={slides}
      data={{}}
      initialSlideIndex={localSlideIndex > -1 ? localSlideIndex : 0}
      initialPayload={localPayload || initialPayload}
      onProgress={async ({ payload }) => {
        console.log("on progress", payload);
      }}
      onSubmit={async ({ payload }) => {
        console.log("on submit", payload);
      }}
      storageKey={storageKey}
    />
  );
};

export default OnboardingV3PageView;
