import { responsiveFontSizes, Theme } from "@mui/material";
import get from "lodash/get";
import partial from "lodash/partial";

import type { TenantName } from "./multitenancy";
import { AvailableTenants } from "./multitenancy";
import { Channel } from "@library/domain/channel";

function getText(theme: Theme, language: string, path: string) {
  const config =
    theme.config.language[language as keyof typeof theme.config.language];
  const text = get(config, path, "");
  return text;
}

export const getTheme = (
  tenant: TenantName = "pearl",
  channel: Channel,
  language = "en-us"
) => {
  let tenantConfig = AvailableTenants.pearl(channel);
  let config = responsiveFontSizes(tenantConfig.theme);
  try {
    tenantConfig =
      AvailableTenants[tenant](channel) ?? AvailableTenants.pearl(channel);
    config = responsiveFontSizes(tenantConfig.theme);
    if (channel?.logoUrl) {
      config.logo.imageSrc = channel.logoUrl;
    } else if (channel?.LogoImage?.url) {
      config.logo.imageSrc = channel.LogoImage.url;
    }
  } catch (e) {
    // do nothing
  }
  config.t = partial(getText, config, language);
  return config;
};
