import React from "react";
// import { ErrorListProps } from "@rjsf/utils";
// import { Alert, Stack, useTheme } from "@mui/material";

export const ErrorListTemplate = () => {
  // const theme = useTheme();
  // const { errors } = props;
  // return (
  //   <Stack spacing={2} m={2}>
  //     {errors.map((error) => {
  //       let property = error.property;
  //       if (property?.startsWith(".")) {
  //         property = property.substr(1);
  //       }
  //       const field =
  //         theme.t(`labels.${property}`)|| property;
  //       return (
  //         <Alert severity="error" sx={{ m: 2 }}>
  //           {field}
  //         </Alert>
  //       );
  //     })}
  //   </Stack>
  // );
  return <></>;
};

export default ErrorListTemplate;
