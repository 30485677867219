import React from "react";
import { Divider, Stack, useTheme } from "@mui/material";

import AdditionalCustomization from "./AdditionalCustomization";
import BrandsWeLove from "./BrandsWeLove";
import SystemInfo from "./SystemInfo";
import SystemMetrics from "./SystemMetrics";
import SystemPhoto from "./SystemPhoto";
import { Quote } from "@library/domain/quote";

const YourSystem = ({
  quote,
  quoteId,
  filter,
  setWarning,
  draft = false,
}: {
  quote: Quote;
  quoteId: string;
  filter: string | undefined;
  setWarning: (_value: string) => void;
  draft?: boolean;
}) => {
  const theme = useTheme();
  const showBrands = theme.config?.showBrands;
  return (
    <Stack
      p={2}
      spacing={0}
      sx={{
        borderRadius: "25px",
        border: "1px solid",
        borderColor: "action.active",
        marginTop: "1.0rem",
      }}
    >
      <SystemPhoto />
      <Divider sx={{ bgcolor: "action.active" }} />
      <SystemInfo />
      {showBrands !== false && (
        <>
          <BrandsWeLove />
          <Divider sx={{ bgcolor: "action.active" }} />
        </>
      )}
      <AdditionalCustomization
        quote={quote}
        quoteId={quoteId}
        setWarning={setWarning}
        draft={draft}
      />
      <SystemMetrics filter={filter} />
    </Stack>
  );
};

export default YourSystem;
