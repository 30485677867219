import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { StyledDialog, classes } from "./styles";

interface OptionsDialogProps {
  open: boolean;
  title?: string;
  buttonText?: string;
  children?: React.ReactNode;
  toggle: () => void;
  onClick?: () => void;
}

const OptionsDialog: React.FC<OptionsDialogProps> = ({
  open,
  toggle,
  onClick,
  children,
  title,
  buttonText = "Confirm",
}) => {
  return (
    <StyledDialog open={open} onClose={toggle}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={{ mt: 3 }}>{children}</DialogContent>
      <DialogActions>
        {onClick && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onClick}
            sx={{ mb: 1 }}
          >
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

export default OptionsDialog;
