import React from "react";
import {
  TableCell,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Root, classes } from "./styles";
import { FloorData, LoadCalculationTableProps } from "./type";

const LoadCalculationTable: React.FC<LoadCalculationTableProps> = ({
  floorData,
  totalBTU,
  viewing,
  sourceTotals,
  onViewingChange,
  temperaturePreferences,
}) => {
  const getCellClasses = (
    isHeat: boolean,
    isLast: boolean,
    isLeft: boolean = false
  ) => {
    return `
          ${isHeat ? classes.heatText : classes.coolText}
          ${isLeft ? (isHeat ? classes.leftHeatTableCell : classes.leftCoolTableCell) : ""}
          ${isLast ? (isHeat ? classes.lastHeatRoomCell : classes.lastCoolRoomCell) : ""}
        `;
  };
  const StyledTableCell: React.FC<{
    children: React.ReactNode;
    isHeat: boolean;
    isLast: boolean;
    isLeft?: boolean;
    className?: string;
  }> = ({ children, isHeat, isLast, isLeft = false, className = "" }) => (
    <TableCell
      align="center"
      className={`${getCellClasses(isHeat, isLast, isLeft)} ${className}`}
    >
      {children}
    </TableCell>
  );

  const renderFloor = (floor: FloorData, index: number) => {
    const floorTotals = floor.rooms.reduce(
      (acc, room) => ({
        heatBTU: acc.heatBTU + room.heatBTU,
        coolBTU: acc.coolBTU + room.coolBTU,
        // sqFt: acc.sqFt + room.sqFt, // Commented out
      }),
      { heatBTU: 0, coolBTU: 0 /* , sqFt: 0 */ }
    );

    return (
      <React.Fragment key={index}>
        <TableRow className={classes.grayRow}>
          <TableCell className={classes.firstColumn} colSpan={3}>
            {floor.name}
          </TableCell>
        </TableRow>
        {floor.rooms.map((room, roomIndex) => {
          const isLastRoom = roomIndex === floor.rooms.length - 1;
          return (
            <TableRow key={roomIndex}>
              <TableCell className={classes.firstColumn}>{room.name}</TableCell>
              {/* <TableCell align="center">~{room.sqFt}</TableCell> */}
              <StyledTableCell isHeat={true} isLast={isLastRoom} isLeft={true}>
                ~{room.heatBTU.toLocaleString()}
              </StyledTableCell>
              {/* <StyledTableCell isHeat={true} isLast={isLastRoom}>
                ~{(room.heatBTU / room.sqFt).toFixed(1)}
              </StyledTableCell> */}
              <StyledTableCell isHeat={false} isLast={isLastRoom} isLeft={true}>
                ~{room.coolBTU.toLocaleString()}
              </StyledTableCell>
              {/* <StyledTableCell isHeat={false} isLast={isLastRoom}>
                ~{(room.coolBTU / room.sqFt).toFixed(1)}
              </StyledTableCell> */}
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell colSpan={1}></TableCell>
          <StyledTableCell isHeat={true} isLast={false}>
            ~{floorTotals.heatBTU.toLocaleString()}
          </StyledTableCell>
          {/* <StyledTableCell isHeat={true} isLast={false}>
            ~{(floorTotals.heatBTU / floorTotals.sqFt).toFixed(1)}
          </StyledTableCell> */}
          <StyledTableCell isHeat={false} isLast={false}>
            ~{floorTotals.coolBTU.toLocaleString()}
          </StyledTableCell>
          {/* <StyledTableCell isHeat={false} isLast={false}>
            ~{(floorTotals.coolBTU / floorTotals.sqFt).toFixed(1)}
          </StyledTableCell> */}
        </TableRow>
      </React.Fragment>
    );
  };

  const renderSourceTable = () => {
    const sourceOrder = [
      "wall",
      "floor",
      "window",
      "ceiling",
      "duct_loss",
      "shg",
      "infiltration",
    ];

    const sourceNames: Record<(typeof sourceOrder)[number], string> = {
      wall: "Walls",
      floor: "Floors",
      window: "Windows",
      ceiling: "Ceilings",
      duct_loss: "System Losses",
      shg: "Infiltration (Sensible)",
      infiltration: "Infiltration (Latent)",
    };

    const totalHeating = sourceTotals?.heating
      ? Object.values(sourceTotals.heating).reduce(
          (sum, value) => sum + value,
          0
        )
      : 0;
    const totalCooling = sourceTotals?.cooling
      ? Object.values(sourceTotals.cooling).reduce(
          (sum, value) => sum + value,
          0
        )
      : 0;

    if (totalHeating === 0 && totalCooling === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} align="center">
              No load calculation data available
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody>
        {sourceOrder.map((source) => (
          <TableRow key={source}>
            <TableCell className={classes.firstColumn}>
              {sourceNames[source]}
            </TableCell>
            <StyledTableCell isHeat={true} isLast={false} isLeft={true}>
              {sourceTotals &&
                Math.round(sourceTotals.heating[source]).toLocaleString()}
            </StyledTableCell>
            <StyledTableCell isHeat={true} isLast={false}>
              {sourceTotals &&
                ((sourceTotals.heating[source] / totalHeating) * 100).toFixed(
                  1
                )}
              %
            </StyledTableCell>
            <StyledTableCell isHeat={false} isLast={false} isLeft={true}>
              {sourceTotals &&
                Math.round(sourceTotals.cooling[source]).toLocaleString()}
            </StyledTableCell>
            <StyledTableCell isHeat={false} isLast={false}>
              {sourceTotals &&
                ((sourceTotals.cooling[source] / totalCooling) * 100).toFixed(
                  1
                )}
              %
            </StyledTableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell className={classes.firstColumn}>Total</TableCell>
          <StyledTableCell
            isHeat={true}
            isLast={true}
            isLeft={true}
            className={classes.heatBackground}
          >
            {Math.round(totalHeating).toLocaleString()}
          </StyledTableCell>
          <StyledTableCell
            isHeat={true}
            isLast={true}
            className={classes.heatBackground}
          >
            100%
          </StyledTableCell>
          <StyledTableCell
            isHeat={false}
            isLast={true}
            isLeft={true}
            className={classes.coolBackground}
          >
            {Math.round(totalCooling).toLocaleString()}
          </StyledTableCell>
          <StyledTableCell
            isHeat={false}
            isLast={true}
            className={classes.coolBackground}
          >
            100%
          </StyledTableCell>
        </TableRow>
      </TableBody>
    );
  };

  const renderTable = () => {
    // FYI: no square footage logic rendering for now
    // const calculateTotalPerSF = (isHeat: boolean) => {
    //   const totalBTU = floorData.reduce(
    //     (sum, floor) =>
    //       sum +
    //       floor.rooms.reduce(
    //         (roomSum, room) => roomSum + (isHeat ? room.heatBTU : room.coolBTU),
    //         0
    //       ),
    //     0
    //   );
    //   const totalSqFt = floorData.reduce(
    //     (sum, floor) =>
    //       sum + floor.rooms.reduce((roomSum, room) => roomSum + room.sqFt, 0),
    //     0
    //   );
    //   return (totalBTU / totalSqFt).toFixed(1);
    // };

    if (viewing === "room") {
      return (
        <TableBody>
          {floorData.length > 0 ? (
            <>
              {floorData.map((floor, index) => renderFloor(floor, index))}
              <TableRow className={classes.grayRow}>
                <TableCell className={classes.firstColumn} colSpan={3}>
                  Total
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1} className={classes.borderlessCell} />
                <StyledTableCell
                  isHeat={true}
                  isLast={true}
                  isLeft={true}
                  className={classes.heatBackground}
                >
                  ~{Math.round(totalBTU.heating).toLocaleString()}
                </StyledTableCell>
                <StyledTableCell
                  isHeat={false}
                  isLast={true}
                  isLeft={true}
                  className={classes.coolBackground}
                >
                  ~{Math.round(totalBTU.cooling).toLocaleString()}
                </StyledTableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                No load calculation data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      );
    } else if (viewing === "source") {
      return renderSourceTable();
    }
  };

  return (
    <Root>
      <Card>
        <Grid container spacing={2} className={classes.tableHeaderContainer}>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="viewing-label">Viewing</InputLabel>
              <Select
                labelId="viewing-label"
                value={viewing}
                label="Viewing"
                onChange={(e) =>
                  onViewingChange?.(e.target.value as "room" | "source")
                }
              >
                <MenuItem value="room">By Room</MenuItem>
                <MenuItem value="source">By Source</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {temperaturePreferences && (
            <Grid
              item
              xs={12}
              sm={10}
              className={classes.tableHeaderInfoContainer}
            >
              <Box className={classes.tableHeaderInfoColumn}>
                <Typography>
                  <strong>Comfort Preferences</strong>
                </Typography>
                <Typography>
                  Winter Temp: {temperaturePreferences.winter}°F
                </Typography>
                <Typography>
                  Summer Temp: {temperaturePreferences.summer}°F
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <TableContainer component={Paper} sx={{ pb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={1}
                  className={classes.borderlessCell}
                />
                <TableCell
                  align="center"
                  className={`${classes.heatText} ${classes.borderlessCell}`}
                  colSpan={viewing === "room" ? 1 : 2}
                >
                  <WhatshotIcon /> ~
                  {Math.round(totalBTU.heating).toLocaleString()} BTU
                </TableCell>
                <TableCell
                  align="center"
                  className={`${classes.coolText} ${classes.borderlessCell}`}
                  colSpan={viewing === "room" ? 1 : 2}
                >
                  <AcUnitIcon /> ~
                  {Math.round(totalBTU.cooling).toLocaleString()} BTU
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.firstColumn}>
                  {viewing === "room" ? "Room" : "Source"}
                </TableCell>
                {/* FYI: not rendering sq footage for now */}
                {/* {viewing === "room" && (
                  <TableCell align="center">Sq.Ft.</TableCell>
                )} */}
                <TableCell
                  align="center"
                  className={`${classes.heatBackground} ${classes.leftHeatHeaderCell}`}
                >
                  BTUh
                </TableCell>
                {/* FYI: not rendering sq footage for now */}
                {viewing !== "room" && (
                  <TableCell
                    align="center"
                    className={`${classes.heatBackground} ${classes.rightHeatHeaderCell}`}
                  >
                    %
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  className={`${classes.coolBackground} ${classes.leftCoolHeaderCell}`}
                >
                  BTUh
                </TableCell>
                {/* FYI: not rendering sq footage for now */}
                {viewing !== "room" && (
                  <TableCell
                    align="center"
                    className={`${classes.coolBackground} ${classes.rightCoolHeaderCell}`}
                  >
                    {viewing === "room" ? "per SF" : "%"}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {renderTable()}
          </Table>
        </TableContainer>
      </Card>
    </Root>
  );
};

export default LoadCalculationTable;
