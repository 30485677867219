import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";
import amanaUnit from "@library/assets/amanaUnit.png";
import PlaceholderImage from "@library/components/PlaceholderImage";
import BrandsWeInstallGrid from "@library/components/Concierge/BrandsWeInstallGrid";

interface SpecLineProps {
  label: string;
  value: string | number | undefined;
  isPrimary?: boolean;
}

const SpecLine: React.FC<SpecLineProps> = ({
  label,
  value,
  isPrimary = false,
}) => (
  <Grid
    item
    xs={12}
    className={classes.contentLineItem}
    sx={{ py: isPrimary ? 0 : 1 }}
  >
    <Typography
      className={classes.boldText}
      color={isPrimary ? "primary.light" : "inherit"}
    >
      {label}
    </Typography>
    <Typography>{value || "Unknown"}</Typography>
  </Grid>
);

const baseLanguageLocation = "concierge.PackageDetails.EquipmentContent";

export const EquipmentContent: React.FC<SectionContentProps> = ({
  theme,
  package: pkg,
}) => {
  const newEquipment = pkg?.polyvectorPayload?.equipmentScope?.new;

  return (
    <Root>
      <Box>
        <Typography
          className={classes.sectionContentDescription}
          sx={{ mb: 2 }}
        >
          {theme?.t(`${baseLanguageLocation}.description`)}
        </Typography>
        <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" className={classes.sectionContentSubtitle}>
              {theme?.t(`${baseLanguageLocation}.heating`)}
            </Typography>
            <Divider sx={{ my: 2 }} className={classes.lightPurpleDivider} />

            <Grid item container xs={12} sx={{ mb: 1 }}>
              <Grid item xs={8}>
                <Typography className={classes.boldText}>
                  {newEquipment?.primary_heating?.brand}{" "}
                  {newEquipment?.primary_heating?.name}
                </Typography>
                <Typography variant="body2">
                  {theme?.t(`${baseLanguageLocation}.exampleUnit`)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {/* TODO: incude other brands */}
                {newEquipment?.primary_heating?.brand
                  .toLowerCase()
                  .includes("amana") ? (
                  <img src={amanaUnit} style={{ height: 100 }} />
                ) : (
                  <PlaceholderImage imageHeight={100} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <SpecLine
                  label="Load"
                  value={`~${newEquipment.primary_heating.capacity?.toLocaleString()} BTU`}
                />
              </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} className={classes.lightPurpleDivider} />

            <SpecLine
              label="Primary"
              value={newEquipment?.primary_heating?.type}
              isPrimary={true}
            />

            <SpecLine
              label="Capacity"
              value={`${newEquipment?.primary_heating?.capacity?.toLocaleString()} btu`}
              isPrimary={false}
            />
            <Divider />

            <SpecLine
              label="SEER2"
              value={newEquipment?.primary_heating?.SEER2}
              isPrimary={false}
            />
            <Divider />

            <SpecLine
              label="EER2"
              value={newEquipment?.primary_heating?.EER2}
              isPrimary={false}
            />
            <Divider />

            <SpecLine
              label="HSPF2"
              value={newEquipment?.primary_heating?.HSPF2}
              isPrimary={false}
            />
            {newEquipment?.secondary_heating && (
              <>
                <Divider
                  sx={{ my: 2 }}
                  className={classes.lightPurpleDivider}
                />

                <SpecLine
                  label="Backup"
                  value={newEquipment?.secondary_heating?.type}
                  isPrimary={true}
                />

                <SpecLine
                  label="Capacity"
                  value={`${newEquipment?.secondary_heating?.capacity?.toLocaleString()} btu`}
                  isPrimary={false}
                />
                <Divider />

                <SpecLine
                  label="SEER2"
                  value={newEquipment?.secondary_heating?.SEER2}
                  isPrimary={false}
                />
                <Divider />

                <SpecLine
                  label="EER2"
                  value={newEquipment?.secondary_heating?.EER2}
                  isPrimary={false}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" className={classes.sectionContentSubtitle}>
              {theme?.t(`${baseLanguageLocation}.cooling`)}
            </Typography>
            <Divider sx={{ my: 2 }} className={classes.lightPurpleDivider} />

            {newEquipment?.cooling && (
              <>
                <Grid item container xs={12} sx={{ mb: 1 }}>
                  <Grid item xs={8}>
                    <Typography className={classes.boldText}>
                      {newEquipment.cooling.brand} {newEquipment.cooling.name}
                    </Typography>
                    <Typography variant="body2">
                      {theme?.t(`${baseLanguageLocation}.exampleUnit`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {/* TODO: incude other brands */}
                    {newEquipment?.primary_heating?.brand
                      .toLowerCase()
                      .includes("amana") ? (
                      <img src={amanaUnit} style={{ height: 100 }} />
                    ) : (
                      <PlaceholderImage imageHeight={100} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                    <SpecLine
                      label="Load"
                      value={`~${newEquipment.primary_heating.capacity?.toLocaleString()} BTU`}
                    />
                  </Grid>
                </Grid>

                <Divider
                  sx={{ mb: 2 }}
                  className={classes.lightPurpleDivider}
                />

                <SpecLine
                  label="Primary"
                  value={newEquipment.cooling.type}
                  isPrimary={true}
                />

                <SpecLine
                  label="Capacity"
                  value={`${newEquipment.cooling.capacity?.toLocaleString()} btu`}
                />
                <Divider />

                <SpecLine label="SEER2" value={newEquipment.cooling.SEER2} />
                <Divider />

                <SpecLine label="EER2" value={newEquipment.cooling.EER2} />
                <Divider />

                <SpecLine
                  label="HSPF2"
                  value={newEquipment.cooling.HSPF2}
                  isPrimary={false}
                />
              </>
            )}
          </Grid>
        </Grid>
        <BrandsWeInstallGrid />
      </Box>
    </Root>
  );
};
