import React from "react";
import { Typography, useTheme } from "@mui/material";

import { SlideComponent } from "../index";

export const Title = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.t(`step.${stepName}.title`)) return null;
  return (
    <React.Fragment>
      {theme
        .t(`step.${stepName}.title`)
        .split("\n")
        .map((t, i) => {
          return (
            <Typography
              key={t}
              variant="h1"
              gutterBottom={false}
              textAlign="center"
              role="heading"
              style={{
                fontWeight: theme.typography.fontWeightBold,
                marginTop: i > 0 ? 5 : theme.spacing(1, "auto"),
              }}
              sx={{
                width: ["100%", "90%", "75%"],
                display: "inline-block",
                fontWeight: "bold",
                px: 2,
                textAlign: "center",
                mt: 4,
              }}
            >
              {t}
            </Typography>
          );
        })}
    </React.Fragment>
  );
};

export const Description = ({ stepName }: SlideComponent) => {
  const theme = useTheme();
  if (!theme.t(`step.${stepName}.description`)) return null;
  return (
    <Typography
      variant="h4"
      sx={{
        width: ["100%", "90%", "75%"],
        display: "inline-block",
        fontWeight: "400",
        marginTop: 2,
        color: theme.palette.grey["900"],
        textAlign: ["center"],
        fontSize: "1.1rem !important",
      }}
    >
      {theme.t(`step.${stepName}.description`)}
    </Typography>
  );
};
