import { Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import omit from "lodash/omit";

import WaitingFixedTime from "@library/components/WaitingFixedTime";
import { SlideComponent } from "../..";
import api from "@library/api";

type Timer = ReturnType<typeof setTimeout>;

export const Header = ({
  data: { job },
  setPayload,
  payload,
  formRef,
}: SlideComponent) => {
  const [intervalId, setIntervalId] = useState<Timer | null>(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_warning, setWarning] = useState("");
  const [hasSetRooms, setHasSetRooms] = useState(false);

  const defaultWarning =
    "Please answer the following questions about your home.";

  const onDone = useCallback(() => {
    setShowTimer(false);
    setHasFetched(true);
  }, []);

  useEffect(() => {
    if (!showTimer && !hasData) {
      setWarning(defaultWarning);
    }
  }, [showTimer, hasData]);

  useEffect(() => {
    if (!job?.id) return;
    if (hasData) return;
    if (hasFetched) return;
    clearInterval(intervalId as Timer);
    setHasFetched(true);
    api
      .get(`job/${job!.id}`)
      .then((response) => {
        const { data } = response;
        if (data.Home.HomeProfile.status === "WAITING") {
          const _intervalId = setTimeout(() => {
            setHasFetched(false);
          }, 5000);
          setIntervalId(_intervalId);
        } else {
          if (data.Home.HomeProfile.status === "COMPLETED") {
            setWarning(
              "We gathered some information about your home - please review."
            );
          }
          if (data.Home.HomeProfile.status !== "COMPLETED") {
            setTimeout(() => {
              setWarning(defaultWarning);
            }, 3000);
          }
          const profile = data.Home?.HomeProfile?.data || { user: {} };
          const userProfile = profile.user || {};
          const homeProfile = omit(profile, ["user"]);
          const newPayload = {
            ...(payload || {}),
            ...(homeProfile || {}),
            user: {
              ...(payload.user || {}),
              ...(userProfile || {}),
            },
          };
          const rooms = newPayload.rooms ?? [];
          const numBedrooms = newPayload.numBedrooms ?? 1;
          const bedrooms = (newPayload.rooms || []).filter(
            (room) => room.type === "BEDROOM"
          );
          if (
            newPayload.rooms?.length &&
            numBedrooms > bedrooms.length &&
            !hasSetRooms
          ) {
            for (let i = 1; i < (newPayload.numBedrooms || 1); i++) {
              rooms.push({
                type: "BEDROOM",
                name: `Bedroom ${i + 1}`,
                isCurrentlyHeated: true,
                isHeatComfortIssue: false,
                isCurrentlyAirConditioned: true,
                isAirConditionComfortIssue: false,
              });
            }
          }
          const numBathrooms = newPayload.numBathrooms ?? 1;
          const bathrooms = (newPayload.rooms || []).filter(
            (room) => room.type === "BATHROOM"
          );
          if (
            newPayload.rooms?.length &&
            numBathrooms > bathrooms.length &&
            !hasSetRooms
          ) {
            for (let i = 1; i < (newPayload.numBedrooms || 1); i++) {
              rooms.push({
                type: "BATHROOM",
                name: `Bathroom ${i + 1}`,
                isCurrentlyHeated: true,
                isHeatComfortIssue: false,
                isCurrentlyAirConditioned: true,
                isAirConditionComfortIssue: false,
              });
            }
          }
          setHasSetRooms(true);
          setPayload(newPayload);
          if (formRef && formRef.current) {
            formRef.current.setState({ formData: newPayload });
          }
          setHasData(true);
          setHasFetched(true);
          setTimeout(() => {
            setShowTimer(false);
          }, 3000);
        }
      })
      .catch(() => {
        setWarning(defaultWarning);
        setTimeout(() => {
          setShowTimer(false);
        }, 3000);
      });
    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, intervalId]);

  return (
    <>
      {/* {warning && (
        <Box mt={2}>
          <Alert severity="info">{warning}</Alert>
        </Box>
      )} */}
      {showTimer && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "fixed",
            zIndex: 99999,
            top: "132px",
            left: 0,
            right: 0,
            bottom: 0,
            px: ["50px", "200px", "300px", "400px"],
            backgroundColor: "rgba(255,255,255,0.90)",
          }}
        >
          <Typography>Searching the web for data about your home...</Typography>
          <WaitingFixedTime
            variant="linear"
            waitTimeInSeconds={!job?.id ? 0 : 10}
            onDone={onDone}
          />
        </Stack>
      )}
    </>
  );
};
