import React from "react";

import { TennantConfig } from ".";

// ""
// const arthurImage = "apps/customer/public/assets/testimonialArthur.jpeg";
const arthurImage = "apps/customer/public/assets/happyCustomer.png";

export const baseConfig = {
  name: "Pearl",
  showBrands: true,
  tiers: {
    default: "base",
    recommended: "base",
    available: ["base", "pearl"],
  },
  language: {
    "en-us": {
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Phone Number",
        homeType: "Home Type",
        yearBuilt: "Year Built",
        sqFootage: "Sq Footage",
        aboveGroundStories: "Above Ground Stories",
        numBedrooms: "Number of Bedrooms",
        ceilingHeight: "Ceiling Height",
        basement: "Basement",
        attic: "Attic",
        garage: "Garage",
        projectType: {
          label: "",
          enum: {
            heating: "Heating",
            air_conditioning: "Air Conditioning",
            weatherization: "Weatherization",
            water_heating: "Water Heating",
            residential_solar: "Residential Solar",
            ev_charging: "Electrical Vehicle Charging",
            energy_efficient_appliances: "Energy-Efficient Appliances",
            electrical_upgrades: "Electrical Upgrades",
            im_not_sure: "I'm not sure yet",
          },
        },
        projectMotivation: {
          label: "",
          enum: {
            support: "I need emergency support.",
            online_quote:
              "I want an online quote - I am shopping for a new system now.",
            directional_estimate:
              "I want a directional estimate - I am budgeting for the future.",
            feedback:
              "I already have a few quotes, and I'd like feedback on them.",
            incentives:
              "I want to search for avaialble discounts, rebates and tax incentives.",
          },
        },
        heatingProjectType: {
          label: "",
          enum: {
            replace: "Replace your current heating system",
            service: "Service or maintain your current heating system",
            add: "Add heat for a part of your home that isn't heated today",
          },
        },
        heatingProjectTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        airConditioningProjectType: {
          label: "",
          enum: {
            replace: "Replace your current air conditioning system",
            service: "Service or maintain your current air conditioning system",
            add: "Add air conditioning for a part of your home that isn't conditioned today",
          },
        },
        airConditioningProjectTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        wholeOrPartialHome: {
          label: "",
          enum: {
            whole: "The entire home.",
            partial: "Only part of the home.",
          },
        },
        floorSize: {
          label: "Floor Size",
          enum: {
            second_floor_is_same_size:
              "2nd floor is the same size as the 1st floor.",
            second_floor_a_bit_smaller:
              "2nd floor is a bit smaller than the 1st floor.",
            second_floor_a_lot_smaller:
              "2nd floor is a lot smaller than the 1st floor.",
          },
        },
        basementType: {
          label: "Basement Type",
          enum: {
            below_grade: 'Fully "below grade" and underground.',
            partially_below_grade: 'Partially "below grade" - garden level.',
            above_grade: 'Fully "above grade" - a walkout.',
          },
        },
        basementFinished: {
          label: "Basement Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        atticType: {
          label: "Attic Type",
          enum: {
            permanent_stairs: 'A "room" accessible by a permanent staircase.',
            hatch: 'A "scuttle attic" accessible through a ceiling hatch.',
            no_attic: "No attic (or not accessible).",
          },
        },
        atticFinish: {
          label: "Attic Finish",
          enum: {
            finished: "Fully finished",
            unfinished: "Completely unfinished",
            partially_finished: "Partially finished",
          },
        },
        garageType: {
          label: "Garage Type",
          enum: {
            fully_detached: "Fully detached from the house.",
            attached_with_no_living_space_above:
              "Attached, but no living space above it.",
            attached_with_living_space_above:
              "Attached, with living space above it.",
          },
        },
        rooms: {
          type: {
            label: "Room type?",
            enum: {
              KITCHEN: "Kitchen",
              DINING_ROOM: "Dining Room",
              LIVING_ROOM: "Living Room",
              MASTER_BEDROOM: "Bedroom",
              BEDROOM: "Bedroom",
              MASTER_BATHROOM: "Bathroom",
              BATHROOM: "Bathroom",
              REC_ROOM: "Recreation Room",
              MEDIA_ROOM: "Media Room",
              OFFICE: "Office",
              DEN: "Den",
              FOYER: "Foyer",
              LAUNDRY_ROOM: "Laundry Room",
            },
          },
          name: {
            label: "Room name?",
          },
          floor: {
            label: "Which floor?",
            enum: {
              basement: "Basement",
              "1": "1st floor",
              "2": "2nd floor",
              "3": "3rd floor",
              attic: "Attic",
            },
          },
          size: {
            label: "What size?",
            enum: {
              SMALL: "Small",
              MEDIUM: "Medium",
              LARGE: "Large",
              XL: "XL",
              XXL: "XXL",
            },
          },
          walls: {
            label: "How many walls have windows?",
          },
          wallsWithWindows: {
            label: "How many windows?",
            enum: {
              none: "None",
              "just one": "Just one.",
              "a few": "A few.",
              "a lot": "A lot.",
              "huge ones": "Huge ones!",
            },
          },
          isCurrentlyHeated: {
            label: "Currently heat?",
          },
          isHeatComfortIssue: { label: "Comfort issues?" },
          heatComfortIssueInfo: { label: "Please explain. (Optional)" },
          isCurrentlyAirConditioned: { label: "Has A/C now?" },
          isAirConditionComfortIssue: { label: "Comfort Issues?" },
          airConditionComfortIssueInfo: { label: "Please explain. (Optional)" },
        },
        heatingEquipmentType: {
          label: "Heating Equipment",
          enum: {
            furnace_with_ducts: "Furnace with ductwork",
            boiler_with_radiators: "Boiler with radiators",
            electric_resistive_with_baseboards:
              "Electric resistive heat with baseboards",
            heat_pump_mini_split: "Heat pump, also known as a “mini split”",
            none_of_these: "None of these match my heating system",
          },
        },
        heatingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        fuelSource: {
          label: "Fuel Source",
          enum: {
            natural_gas: "Natural gas",
            fuel_oil: "Fuel oil",
            electricity: "Electricity",
            propane: "Propane",
            something_else: "Another fuel source",
            i_don_t_know: "I don't know",
          },
        },
        coolingEquipmentType: {
          label: "Cooling Equipment",
          enum: {
            central_air_conditioning: "Central air conditioning",
            window_unit_air_conditioning: "Window-unit air conditioning",
            evaporative_cooling: "Evaporative cooling",
            heat_pump_mini_split: 'Heat pump, also known as a "Mini Split"',
            something_else: "Another system",
            no_air_conditioning: "No air conditioning",
          },
        },
        coolingEquipmentTypeMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        materials: {
          exteriorWalls: { label: "Exterior Walls" },
          interiorWalls: { label: "Interior Walls" },
        },
        exteriorWalls: {
          label: "Exterior Walls",
          enum: {
            stucco: "Stucco",
            vinyl: "Vinyl",
            fiber_cement: "Fiber Cement",
            brick: "Brick",
            wood: "Wood",
            stone: "Stone",
            metal: "Metal",
          },
        },
        interiorWalls: {
          label: "Interior Walls",
          enum: {
            stucco: "Stucco",
            drywall: "Drywall",
            plaster: "Plaster",
            brick: "Brick",
            wood_panel: "Wood Panel",
            concrete: "Concrete",
            ceramic_tile: "Ceramic Tile",
          },
        },
        windowType: {
          enum: {
            single: "Single-pane windows",
            double: "Double-pane windows",
            triple: "Triple-pane windows",
            im_not_sure: "I'm not sure",
          },
        },
        upgrades: {
          label: "Upgrades",
          enum: {
            added_insulation: "Added insulation",
            upgraded_windows: "Upgraded windows",
            air_sealing: "Air sealing",
            upgraded_electrical_panel: "Upgraded electrical panel",
            duct_insulation_sealing: "Duct insulation / sealing",
            something_else: "Something else",
          },
        },
        upgradesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        issues: {
          label: "Issues",
          enum: {
            roof_damage: "Roof damage",
            asbestos: "Asbestos",
            wall_damage: "Wall damage",
            visible_mold: "Visible mold",
            missing_windows: "Missing windows",
            standing_water: "Standing water",
            electrical_issues: "Electrical issues",
            indoor_air_quality: "Indoor air quality",
          },
        },
        issuesMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        draftyStuffy: {
          label: "Drafty / Stuffy",
          enum: {
            yes_drafty:
              "Yes, there can be cool drafts even when windows are shut.",
            yes_stuffy:
              "Yes, sometimes the air quality can feel stuffy or uncomfortable.",
            no: "All good!",
          },
        },
        draftyStuffyMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        homeQuality: {
          label: "Home Quality",
          enum: {
            yes: "Yes!",
            no_always_cold: "No, too cold in the winter.",
            no_always_hot: "No, too hot in the summer.",
            uneven: "No, some rooms are too hot or too cold.",
          },
        },
        homeQualityMoreInfo: {
          label: "Anything else we should know? (Optional)",
        },
        whyAreYouShoppingToday: {
          label: "Why Are You Shopping",
          enum: {
            equipmentBroken: "My equipment is broken.",
            equipmentLastLeg: "My equipment is old, I want to replace it.",
            moreEfficientSystem: "I want a more efficient system.",
            justBrowsing: "I'm just browsing.",
          },
        },
        whatsImportantToYou: {
          label: "What's Important To You",
          enum: {
            lowUpFrontCost: "Low up-front cost",
            lowerMonthlyBills: "Reduce energy costs",
            climateImpact: "Climate impact",
            betterComfort: "Comfort",
            somethingElse: "Other",
          },
        },
        whatsImportantToYouInfo: {
          label: "Anything else we should know? (Optional)",
        },
        ownOrRent: {
          label: "Own or Rent",
          enum: {
            own: "Own",
            rent: "Rent",
          },
        },
        peopleLiveInHome: {
          label: "People Live In Home",
        },
        communicationPreference: {
          label: "Communication Preference",
          enum: {
            email: "Email",
            text: "Text",
            call: "Call",
          },
        },
      },
      step: {
        ProjectTypeStep: {
          title: "Welcome to Pearl Edison Concierge!",
          description: "What type of project are you working on?",
        },
        HeatingProjectStep: {
          title: "Great! Tell us a bit about your project.",
          description: "Do you want to...",
        },
        AirConditioningProjectStep: {
          title: "Great! Tell us a bit about your project.",
          description: "Do you want to...",
        },
        HeatingOrAirConditioningServiceStep: {
          title: "Great, let's schedule service.",
          description: "Please provide your name and contact information.",
        },
        HeatingOrAirConditioningScheduleServiceStep: {
          title:
            "Thanks. You can schedule any time, or call us directly if it's an emergency.",
          description: "Reach us any time at 000-000-0000",
        },
        HeatingOrAirConditioningReplacementStep: {
          title: "Great, we can support you with that.",
          description: "How do you want to get started?",
        },
        HeatingOrAirConditioningReplaceEmergencyStep: {
          title: "We can help with your emergency. ",
          description: [
            "Please provide your name and contact information",
            <br />,
            "Reach us anytime at 000-000-0000",
          ],
        },
        HeatingOrAirConditioningReplaceEmergencyScheduleStep: {
          title:
            "Thanks. You can schedule any time, or call us directly if it's an emergency.",
          description: "Reach us any time at 000-000-0000",
        },
        HeatingOrAirConditioningWholeOrPartialStep: {
          title:
            "Will this system serve the entire home, or only part of the home?",
          description: "",
        },
        LetsGetStartedStep: {
          title: "Let's get started.",
          description: "Pearl Edison is your Energy Efficiency Concerge.",
        },
        UploadQuoteStep: {
          title: "Upload your quote to get started.",
          description:
            "Your Pearl Edison Advisor will review and provide feedback.",
        },
        IncentivesAddressStep: {
          title: "What's your address?",
          description:
            "We'll search for available discounts, rebates and tax incentives.",
        },
        AddressStep: {
          title:
            "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
          description: "",
        },
        OnboardingStep: { title: "", description: "" },
        GettingStartedStep: {
          title: "Let’s get started.",
          description:
            "We’ll help you design the right efficient heating and air conditioning system for your unique home and goals. It only takes 10 minutes.",
          step1Title: "Get your instant design and estimate",
          step1Description:
            "Tell us about your home and goals, then find your rebates. We’ll design the right system for your unique home and goals.",
          step1Time: "~10 min",
          step2Title: "Meet your advisor",
          step2Description:
            "We’ll confirm final details, answer your questions, and finalize your design.",
          step2Time: "within 1 day",
          step3Title: "Electrify your home",
          step3Description:
            "When you’re ready, we’ll schedule your installation with one of our licensed, vetted, experienced contractor partners.",
          step3Time: "within 2 weeks",
        },
        HomeConfirmationStep: {
          title: "Is this your home?",
          description: "",
        },
        FullNameStep: {
          title: "Tell us about yourself.",
          description: "We'll send you a link to view your estimate.",
        },
        HomeProfileStep: {
          title: "Please confirm details about your home.",
          description: "",
        },
        FloorSizeStep: {
          title: "How large is each floor?",
          description: "",
        },
        BasementTypeStep: {
          title: "Which basement is most like yours?",
          description: "",
        },
        BasementFinishedStep: {
          title: "Is your basement finished?",
          description: "",
        },
        AtticTypeStep: {
          title: "Which attic is most like yours?",
          description: "",
        },
        AtticFinishedStep: {
          title: "Is your attic finished?",
          description: "",
        },
        GarageStep: {
          title: "Which garage is most like yours?",
          description: "",
        },
        FloorPlanStep: {
          title: "List all of the rooms in your home",
          description: "",
        },
        RoomsConfigurationStep: {
          title: "Which rooms do you need to heat and cool?",
          description: "",
        },
        HeatingEquipmentStep: {
          title: "What kind of heating equipment do you currently have?",
          description: "",
        },
        FuelSourceStep: {
          title: 'What is your "fuel source" for heating?',
          description: "",
        },
        HeatRoomsStep: {
          title:
            "Which rooms do you heat today? Do you experience any winter comfort issues?",
          description: "",
        },
        AirConditioningStep: {
          title:
            "What kind of air conditioning equipment do you currently have?",
          description: "",
        },
        AirConditioningRoomsStep: {
          title:
            "Which rooms have air conditioning today? Do you experience any summer comfort issues?",
          description: "",
        },
        MaterialsSteps: {
          title: "What material are (most of) your walls made of?",
          description: "",
        },
        WindowsStep: {
          title: 'What "type" are (most of) the windows in your home?',
          description: "",
        },
        UpgradeStep: {
          title:
            "Are you aware of any upgrades to the home, since it was built?",
          description: "",
        },
        IssuesStep: {
          title: "Are you aware of any of these (common) issues at your home?",
          description: "",
        },
        TemperatureStep: {
          title: "What temperature do you usually set the thermostat at?",
          description: "",
          whyAreWeAsking:
            "We use this to estimate your energy consumption and the impact of your new system on your bills.",
        },
        DraftyStuffyStep: {
          title: 'Does your home ever feel "drafty" or "stuffy"?',
          description: "",
        },
        HomeQualityStep: {
          title: "Is the temperature of your home usually comfortable?",
          description: "",
        },
        WhyAreYouShoppingStep: {
          title: "Why are you shopping today?",
          description: "",
        },
        WhatsImportantToYouStep: {
          title: "What's important to you?",
          description: "",
        },
        AlmostDone: {
          title: "Almost done.",
          description: "Let‘s find your rebates.",
        },
        OwnOrRentStep: {
          title: "Do you own or rent your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        PeopleLiveInHomeStep: {
          title: "How many people live in your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        HouseHoldIncomeStep: {
          title: "What is your annual household income?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        CommunicationPreferencesStep: {
          title: "Communication Preference",
          description: "",
        },
        FinalStep: {
          title: "Are you ready to see your quote?",
          description: 'Pressing "submit" will generate your estimate.',
        },
        GetEstimates: { title: "Creating your estimates!", description: "" },
      },
      concierge: {
        title: ", here is your instant estimate!", // prepended by user's name
        loadingTitle: ", loading your instant estimate!", // prepended by user's name
        description:
          "Review your options for efficient heating and cooling. Then, connect with your Pearl Edison advisor to finalize your quote.",
        ScheduleFooter: {
          finalizeButtonDescription: "Ready to finalize your quote?",
          finalizeButtonLabel: "Schedule a Home Assessment",
          consultationButtonDescription: "Still have questions?",
          consultationButtonLabel: "Schedule a Virtual Consultation",
          yourAdvisor: "Your Advisor",
        },
        SchedulingDialog: {
          done: "Done",
          nextStep: "Take the next step!",
          schedule: "Schedule a", // appended with title
          assessment: {
            duration: "1 hr 30 min",
            title: "Home Assessment",
            subtitle: "Ready to finalize your quote?",
            eventDescription:
              "Please select a day and time that works for you to have a certified, Pearl Edison contractor visit your home for an in-person assessment.",
          },
          consultation: {
            duration: "1 hr",
            title: "Virtual Consultation",
            subtitle: "Still have questions?",
            eventDescription:
              "Please select a day and time that works for you to have a conversation with a Pearl Edison specialist. We are Looking forward to discussing your home!",
          },
        },
        SwiperCarousel: {
          title: "Your options for", // appended with address
        },
        PackageDetails: {
          SummaryContent: {
            subtitle: "Understanding Your System",
            learnMore: "Learn More",
            summaryQuote: "Summary Quote",
            cost: "Cost",
            rebates: "Rebates",
            costAfterRebates: "Cost After Rebates",
          },
          ScopeContent: {
            title: "Scope of Work",
            description:
              "We designed an efficient heating and cooling system based on your unique home and the goals and preferences you shared. See your detailed scope of work below. Your Pearl Edison advisor can answer an of your questions!",
            total: "Total Cost Before Rebates",
            scope: "Scope",
            learnMore: "Learn More",
            otherOptions: "Other Options",
            installationNote:
              "Your installation will be performed by a Pearl Edison contractor.",
            options: {
              fasterInstallation: {
                title: "Need a faster installation?",
                description: "Have an urgent need? We've got you covered.",
                dialogSubtitle: "Have an urgent need?",
                dialogText:
                  "Call (312) 358-5085 or confirm your number below and we'll be in touch ASAP.",
                dialogButtonText: "Contact Me",
              },
              customizeSystem: {
                title: "Want to customize your system?",
                description:
                  "You can modify the scope to better reflect your goals.",
                dialogButtonText: "Confirm Preference",
                options: {
                  waterHeater: {
                    title: "Water Heater",
                    description:
                      "Replacing your water heater could result in additional savings.",
                  },
                  airQuality: {
                    title: "Air Quality",
                    description:
                      "Consider investing in air purification, filtration, and humidification.",
                  },
                  insulation: {
                    title: "Insulation",
                    description:
                      "Adding insulation means better comfort and additional savings.",
                  },
                  other: {
                    title: "Something Else",
                  },
                },
              },
              brandPreference: {
                title: "Have a brand preference?",
                description:
                  "Let us know! Note: this often adds time and cost.",
                dialogSubtitle: "Tell us about your brand preferences",
                dialogFooter: "Note: this often adds time and cost.",
                dialogButtonText: "Save Message",
              },
              request: "Request",
            },
          },
          PricingContent: {
            subtitle: "Cost Breakdown",
            beforeRebates: "Before Rebates",
            totalPayment: "Total Payment",
            afterRebates: "After All Rebates + Discounts",
            afterTaxCredits: "After Tax Credits",
            rebates: {
              payNow: {
                title: "Rebates + discounts that pay now",
                description:
                  "Applied at point of sale by Pearl Edison, to lower your price",
              },
              payLater: {
                title: "Rebates + discounts that pay later",
                description:
                  "Applied after you've paid for your new system and installation is complete",
              },
              taxCredits: {
                title: "Tax Credits",
                description:
                  "Applied as a tax refund, which will save you money if you would otherwise owe in federal or state taxes",
              },
              additional: {
                title: "Additional Rebates",
              },
            },
            paymentOptions: {
              subtitle: "Payment Options",
              options: {
                first: {
                  title: "Option #1: Self-Finance",
                },
                second: {
                  title: "Option #2: Low-Interest Loan",
                },
              },
            },
          },
          LoadCalculationContent: {
            title: "Load Calculation",
            description:
              "During your home assessment, we analyzed your heating and cooling load - the amount of heat we need to move to keep you warm in the winter and cool in the summer.",
          },
          EquipmentContent: {
            title: "Equipment & Tech Specs",
            description:
              "We only install high-quality equipment from reputable manufacturers that back their products with a 10-year warranty on parts and labor.",
            heating: "Heating",
            cooling: "Cooling",
            exampleUnit:
              "Example system that meets these technical specifications",
          },
          WhyPearlEdisonContent: {
            title: "Why Pearl Edison?",
            description:
              "We provide high-quality advice, properly design your system, and arrange installation through a vetted contractor partner.",
            howItWorks: {
              title: "How It Works",
              items: {
                responsible: {
                  title: "We are responsible for your project, end-to-end.",
                  description:
                    "We are responsible for the design, installation, and commissioning of your system. (We are not a lead gen service sell comfort, not data.) That means we're on the hook if you aren't happy.",
                },
                guarantee: {
                  title:
                    "We guarantee your price, including rebates + incentives.",
                  description:
                    "After your home assessment, we set a 'ceiling' for your price, including applicable rebates and incentives. We work on a fixed-price basis, even if we are going to lose money on the job.",
                },
                negotiate: {
                  title: "We negotiate on your behalf to get a good deal.",
                  description:
                    "We negotiate scoping and pricing with our network of contractor partners. We typically save our customers ~10% vs. going direct. If we're able to bring the price down, we'll reduce your price!",
                },
                money: {
                  title: "We make money if (and only if) you save money.",
                  description:
                    "We earn a fee on your project, as a percentage of the savings we negotiate. If we can't identify a contractor willing to perform the work at your guaranteed price, then we pay",
                },
              },
            },
            ourStandards: {
              title: "Our Standards",
              items: {
                licenseCheck: {
                  title: "License Check",
                  description:
                    "We perform a license check to ensure that all Pearl Edison contractor and subcontractor partners are operating legally and ethically, and are qualified by the state to work in your area.",
                },
                technicalVetting: {
                  title: "Technical Vetting",
                  description:
                    "Our Operations team evaluates every Pearl Edison contractor partner's technical credentials to ensure that they are capable of meeting rigorous standards for installation and commissioning.",
                },
                ethicalVetting: {
                  title: "Ethical Vetting",
                  description:
                    "We work with partners who share our values - our commitment to serving customers with integrity and our commitment to grounding system design in building science and best pratices.",
                },
                codeOfConduct: {
                  title: "Code of Conduct",
                  description:
                    "Every Pearl Edison contractor partner signs up to a code of conduct. You can read it here. Failure to abide by the code of conduct results in removal from the Pearl Edison network.",
                },
              },
            },
            featuredPartner: {
              title: "Featured Partner",
              companyName: "Air Doctors",
              description:
                "We are a proud partner in the Pearl Edison network. Our goals are aligned - delivering energy-efficient products, quality work, and outstanding customer service. Consider us a lifer!",
              founded: { label: "Founded", value: "2004" },
              headquarters: {
                label: "Headquarters",
                value: "Detroit, MI",
              },
              certifications: "Certifications",
            },
          },
          TestimonialContent: {
            title: "Testimonials",
            description:
              "Hear from neighbors who worked with Pearl Edison to buy an efficient heating and air conditioning system.",
            whyElectric: "Why did you go electric?",
            projectScopeLabel: "Project scope label",
            items: {
              julianne: {
                customerName: "Julianne L.",
                location: "Detroit, MI",
                why: "To add air conditioning and improve my summer comfort.",
                scopeLabel: "3-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison has been so great to work with! They are communicative and helpful through what could be a very complicated process. They make it simple and easy.",
              },
              macon: {
                customerName: "Macon G.",
                location: "Detroit, MI",
                why: "My bones were achin' in the winter!",
                scopeLabel: "4-ton dual-fuel heat pump",
                testimonial:
                  "Pearl Edison helped us put our home in a better position with heating and cooling. The team was kind and very efficient. Thank you so much for your time and effort to make us comfortable in our home!",
              },
              arthur: {
                customerName: "Arthur M.",
                location: "Detroit, MI",
                why: "To get AC for the first time and reduce my bills.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "Very happy with my new system. I'm 60 years old and never had air conditioning before. Just in time for summer. Go Pistons!",
                imageSrc: arthurImage,
              },
              debra: {
                customerName: "Debra A.",
                location: "Detroit, MI",
                why: "My bills were really hight in the winter.",
                scopeLabel: "2-ton dual-fuel heat pump",
                testimonial:
                  "My heating bill was $700 per month and I was always cold at my house. Pearl Edison helped me fixed the problem. My bills are much lower now!",
              },
            },
          },
        },
      },
    },
    "en-es": {
      step: {
        HomeConfirmationStep: {
          title: "Esta su casa?",
          description: "",
        },
      },
    },
  },
} as unknown as TennantConfig;
