import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import ImpactChart from "../ImpactChart";
import {
  energyPricesOptions,
  energyUsageOptions,
  weatherOptions,
} from "../../constants";

// Sample data structure
const data = [
  { month: "Jan", monthlyBill: 200, savings: 100 },
  { month: "Feb", monthlyBill: 180, savings: 100 },
  { month: "Mar", monthlyBill: 150, savings: 75 },
  { month: "Apr", monthlyBill: 120, savings: 10 },
  { month: "May", monthlyBill: 140, savings: 20 },
  { month: "Jun", monthlyBill: 100, savings: 35 },
  { month: "Jul", monthlyBill: 90, savings: 35 },
  { month: "Aug", monthlyBill: 95, savings: 30 },
  { month: "Sep", monthlyBill: 110, savings: 20 },
  { month: "Oct", monthlyBill: 130, savings: 10 },
  { month: "Nov", monthlyBill: 160, savings: 75 },
  { month: "Dec", monthlyBill: 190, savings: 100 },
];

export const BillImpactContent: React.FC<SectionContentProps> = () => {
  // State for dropdown values
  const [weather, setWeather] = useState("Average");
  const [energyUsage, setEnergyUsage] = useState("Status Quo");
  const [energyPrices, setEnergyPrices] = useState("Status Quo");

  return (
    <Root>
      <Box>
        <Typography
          className={classes.sectionContentDescription}
          sx={{ mb: 2 }}
        >
          We project that you'll save <strong>$700 per year initially</strong> -
          $550 in the winter on heating, $150 in the summer on air conditioning
          - and greater than{" "}
          <strong>$10,500 over the life of your system.</strong>
        </Typography>
        {/* Dropdowns container */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Weather</InputLabel>
              <Select
                value={weather}
                label="Weather"
                onChange={(e) => setWeather(e.target.value)}
              >
                {weatherOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>My Energy Usage</InputLabel>
              <Select
                value={energyUsage}
                label="My Energy Usage"
                onChange={(e) => setEnergyUsage(e.target.value)}
              >
                {energyUsageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Energy Prices</InputLabel>
              <Select
                value={energyPrices}
                label="Energy Prices"
                onChange={(e) => setEnergyPrices(e.target.value)}
              >
                {energyPricesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ImpactChart data={data} />
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
