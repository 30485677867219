import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import { SectionContentProps } from "../../types";
import { Root, classes } from "./styles";
import PlaceholderImage from "@library/components/PlaceholderImage";

const testimonialKeys = ["julianne", "macon", "arthur", "debra"];
const baseLanguageLocation = "concierge.PackageDetails.TestimonialContent";

const getTestimonialImage = (key: string) => {
  try {
    return `../../public/assets/testimonial${key.charAt(0).toUpperCase() + key.slice(1)}.jpeg`;
  } catch (error) {
    return "";
  }
};

export const TestimonialContent: React.FC<SectionContentProps> = ({
  theme,
}) => (
  <Root>
    <Box>
      <Typography className={classes.sectionContentDescription} sx={{ mb: 2 }}>
        {theme?.t(`${baseLanguageLocation}.description`)}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {testimonialKeys.map((key) => (
            <Card key={key} className={classes.lightPurpleCard} sx={{ mb: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  {getTestimonialImage(key) ? (
                    <img
                      src={getTestimonialImage(key)}
                      className={classes.testimonialImage}
                    />
                  ) : (
                    <PlaceholderImage imageHeight={200} />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={classes.customerInformationContainer}
                >
                  <Typography
                    color="primary.light"
                    className={classes.boldText}
                  >
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.customerName`
                    )}
                  </Typography>
                  <Typography color="primary.light">
                    {theme?.t(`${baseLanguageLocation}.items.${key}.location`)}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.whyElectric`)}
                  </Typography>
                  <Typography variant="body2">
                    {theme?.t(`${baseLanguageLocation}.items.${key}.why`)}
                  </Typography>
                  <Typography className={classes.boldText}>
                    {theme?.t(`${baseLanguageLocation}.whyElectric`)}:
                  </Typography>
                  <Typography variant="body2">
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.scopeLabel`
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.testimonialContainer}
                >
                  <Typography sx={{ fontSize: 20 }} color="primary.light">
                    "
                    {theme?.t(
                      `${baseLanguageLocation}.items.${key}.testimonial`
                    )}
                    "
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Box>
  </Root>
);
