import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import omit from "lodash/omit";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";
import queryString from "query-string";
import { withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  OnboardingPayload,
  initialOnboardingPayload,
} from "@library/domain/onboarding";

import widgets from "@library/components/form/widgets";

import {
  getNextSlide,
  getPreviousSlide,
  getSpecificSlide,
  getSlide,
  SlideSchema,
} from "./slides";
import { SettingsContext } from "@library/settings/provider";
import api from "@library/api";
import SkipModal from "./SkipModal";
import isFunction from "lodash/isFunction";
import ProgressBar from "./ProgressBar";

import PreFetchImages from "@library/components/form/assets/PreFetchImages";
import FieldErrorTemplate from "@library/components/form/templates/FieldErrorTemplate";
import ErrorListTemplate from "@library/components/form/templates/ErrorListTemplate";
import { TenantName } from "@library/theme/multitenancy";

const schema = (): RJSFSchema => {
  return {
    type: "string",
  };
};

const uiSchema = (): UiSchema => {
  return {
    "ui:autocomplete": "off",
  };
};

interface OnboardingContext {
  payload: Partial<OnboardingPayload>;
  setPayload: (value: Partial<OnboardingPayload>) => void;
  slideIndex: number;
  setSlideIndex: (value: number) => void;
}

export const OnboardingContext = createContext<OnboardingContext>({
  payload: { ...initialOnboardingPayload },
  setPayload: () => {},
  slideIndex: 0,
  setSlideIndex: () => {},
});

export interface SlideComponent {
  stepName: string;
  payload: Partial<OnboardingPayload>;
  setPayload: (payload: Partial<OnboardingPayload>) => void;
  setSlideDirection?: (direction: number) => void;
  setSlideIndex?: (index: number) => void;
  getSpecificSlide?: ({
    index,
    keyIndex,
    numIndex,
  }: {
    index: number;
    keyIndex?: string;
    numIndex?: number;
  }) => Promise<number>;
  slideIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSlideState?: (val: any) => void;
  formRef: React.RefObject<React.Component>;
  setError?: (val: string | ReactElement) => void;
  error?: string | ReactElement;
  setLoading?: (value: boolean) => void;
  data: {
    home?: {
      id: string;
    };
    job?: {
      id: string;
    };
    quote?: {
      id: string;
    };
    onboarding?: {
      id: string;
    };
  };
}

const NoComponent = () => {
  return <></>;
};

export const OnboardingPageView = ({
  draft = false,
  draftOnboardingPayload,
}: {
  draft?: boolean;
  draftOnboardingPayload?: Partial<OnboardingPayload>;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, setUser, setIsAuthenticated, setChannel } =
    useContext(SettingsContext);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const { channelId } = useContext(SettingsContext);
  const homes = user.Home ?? [];
  const home = homes[0];
  const jobs = home?.Job ?? [];
  const job = jobs[0];
  const quotes = job?.Quote ?? [];
  const quote = quotes[0];
  const onboarding = quote?.Onboarding ?? [];
  const dbPayload = onboarding.data ?? {};
  const dbStep = onboarding.step ?? {};
  const [payload, setPayload] = useState<Partial<OnboardingPayload>>({
    ...initialOnboardingPayload,
    ...dbPayload,
    ...(draftOnboardingPayload ?? {}),
    user: {
      ...initialOnboardingPayload.user,
      ...omit(user, ["Home"]),
      ...dbPayload.user,
      channelId,
      ...(draftOnboardingPayload?.user ?? {}),
    },
  });

  const [skipModalOpen, setSkipModalOpen] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(dbStep.activeStep ?? 0);
  const [nextSlideIndex, setNextSlideIndex] = useState<number>(slideIndex);
  const [slideDirection, setSlideDirection] = useState<number>(0);
  const [slide, setSlide] = useState<SlideSchema>({
    key: "",
    schema,
    uiSchema,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [slideState, setSlideState] = useState<Record<string, any>>({}); // Useful current slide state
  const [error, setError] = useState<string | ReactElement>("");
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_searchParams, setSearchParams] = useSearchParams();

  const Form = withTheme(Theme);
  const formRef = useRef(null);

  const Header = slide.Header ?? NoComponent;
  const Title = slide.Title ?? NoComponent;
  const Description = slide.Description ?? NoComponent;
  const Footer = slide.Footer ?? NoComponent;
  const Callout = slide.Callout ?? NoComponent;
  const fetchOnLoad = slide.fetchOnLoad;

  useEffect(() => {
    if (onboarding && onboarding.status === "COMPLETED") {
      navigate(`concierge/${quote.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding, quote]);

  useEffect(() => {
    if (quote?.channelId) {
      setChannel(quote.channelId as TenantName);
    }
  }, [quote, setChannel]);

  useEffect(() => {
    if (fetchOnLoad) {
      fetchOnLoad({ slideState, payload, theme }).then((newSlideState) => {
        setSlideState(newSlideState);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnLoad]);

  useEffect(() => {
    getSlide(slideIndex).then((nextSlide) => {
      setSlide(nextSlide);
    });
    getNextSlide({
      index: slideIndex,
      payload,
      setPayload: () => {},
      setError: () => {},
      user,
      setUser: () => {},
      setIsAuthenticated: () => {},
      executeOnNext: false,
      setLoading: () => {},
      theme,
      draft,
    }).then((nextSlideIndex) => {
      if (nextSlideIndex !== slideIndex) {
        setError("");
      }
      setNextSlideIndex(nextSlideIndex);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex]);

  useEffect(() => {
    const qs = queryString.parse(location.search) ?? {};
    if (qs.s) {
      const slideIndexParam = parseInt(String(qs.s), 10);
      if (slideIndexParam > -1) {
        handlePageChange(slideIndexParam);
        if (draft) return;
        window.history.replaceState({}, document.title, "/onboarding");
        setSearchParams({ s: "" });
      }
    }
  }, [slideIndex, search, setSearchParams, draft]);

  const submitForm = useCallback(() => {
    const submitError =
      "We were unable generate your quote -- please wait and try again or chat with us using the widget in the lower right corner.";
    if (onboarding.id) {
      api
        .put(`onboarding/${onboarding.id}`, {
          payload,
          activeStep: slideIndex,
        })
        .then(() => {
          // Submit the form
          setLoading(true);
          api
            .post(`onboarding/${onboarding.id}`, {
              tiers: theme.config?.tiers?.available ?? ["base", "pearl"],
            })
            .then((response) => {
              setLoading(false);
              const { quoteId } = response.data ?? {};
              if (!quoteId) {
                setError(submitError);
              } else {
                navigate(`/concierge/${quoteId}`);
              }
            })
            .catch(
              ({
                response: {
                  data: { error = "" },
                },
              }) => {
                setLoading(false);
                setError(error ? String(error) : submitError);
              }
            );
        });
    }
  }, [
    onboarding.id,
    payload,
    slideIndex,
    theme.config?.tiers?.available,
    navigate,
  ]);

  useEffect(() => {
    if (isFunction(slide.visible)) {
      const isVisible = slide.visible?.({ payload, theme });
      if (!isVisible && nextSlideIndex === -1) {
        if (onboarding?.id) {
          setLoading(true);
          submitForm();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSlideIndex]);

  useEffect(() => {
    if (slideDirection !== 0) {
      setShow(false);
    }
  }, [slideDirection]);

  useEffect(() => {
    if (show) return;
    /// @XXX Is there a better way of triggering the animation?

    setTimeout(() => {
      if (slideDirection === 1) {
        getNextSlide({
          index: slideIndex,
          payload,
          setPayload,
          setError,
          user,
          setUser,
          setIsAuthenticated,
          executeOnNext: true,
          setLoading,
          theme,
          draft,
        }).then(async (newSlideIndex) => {
          if (newSlideIndex === -1) {
            submitForm();
          } else {
            if (onboarding.id) {
              api.put(`onboarding/${onboarding.id}`, {
                payload,
                activeStep: newSlideIndex,
              });
            }
            setSlideIndex(newSlideIndex);
            window.scrollTo(0, 0);
          }
        });
      }
      if (slideDirection === -1) {
        getPreviousSlide({
          index: slideIndex,
          payload,
          theme,
        }).then(async (newSlideIndex) => {
          if (onboarding.id) {
            api.put(`onboarding/${onboarding.id}`, {
              payload,
              activeStep: newSlideIndex,
            });
          }
          setSlideIndex(newSlideIndex);
          window.scrollTo(0, 0);
          setError("");
        });
      }
      setShow(true);
      setTimeout(() => {
        setSlideDirection(0);
      }, 200);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideDirection, show, payload]);

  const handleSkip = (toSkip: boolean) => {
    setSkipModalOpen(false);
    if (toSkip) {
      setSlideDirection(1);
    }
  };

  const background = (slide.background && slide.background(theme)) || "none";

  let direction = "left" as keyof { left: true; right: true };
  if (show) {
    if (slideDirection === -1) {
      direction = "right";
    } else if (slideDirection === 1) {
      direction = "left";
    }
  } else {
    if (slideDirection === -1) {
      direction = "left";
    } else if (slideDirection === 1) {
      direction = "right";
    }
  }

  const handlePageChange = (_slideIndex: number) => {
    setSlideIndex(_slideIndex);
    window.scrollTo(0, 0);
  };

  return (
    <OnboardingContext.Provider
      value={{ payload, setPayload, slideIndex, setSlideIndex }}
    >
      <div style={{ overflow: "hidden" }}>
        {isLoading && (
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              zIndex: 9999,
              position: "fixed",
              top: "0px",
              left: "0px",
              right: "0px",
              bottom: "0px",
              background: `rgba(255,255,255,${
                nextSlideIndex === -1 ? 1.0 : 0.4
              })`,
            }}
          >
            <Stack
              sx={{
                top: "calc(50% - 20px)",
                left: "calc(50% - 20px)",
                position: "relative",
              }}
            >
              <CircularProgress />
            </Stack>
          </Box>
        )}
        {!draft && slide.progress !== false && (
          <>
            <ProgressBar
              slide={slide}
              slideIndex={slideIndex}
              onChange={handlePageChange}
            />
            <Box mt={4}>&nbsp;</Box>
          </>
        )}
        <Slide
          in={Boolean(show && direction.length)}
          direction={direction}
          easing={{
            enter:
              slide.animation !== false
                ? theme.transitions.easing.easeOut
                : "0.0s",
            exit:
              slide.animation !== false
                ? theme.transitions.easing.sharp
                : "0.0s",
          }}
        >
          <Box
            px={[1, 2, 8]}
            pb={8}
            pt={3}
            overflow="auto"
            flex={1}
            flexGrow={1}
            sx={{
              background,
              backgroundSize: "cover",
              minHeight: `calc(100vh - ${slide.progress !== false ? 128 : 64}px)`,
            }}
          >
            <Stack width="100%" alignItems="center">
              {Title && (
                <Title
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={{ job, home, quote, onboarding }}
                  formRef={formRef}
                />
              )}
              {Description && (
                <Description
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={{ job, home, quote, onboarding }}
                  formRef={formRef}
                />
              )}
              {Header && (
                <Header
                  stepName={slide.key}
                  slideIndex={slideIndex}
                  payload={payload}
                  setPayload={setPayload}
                  data={{ job, home, quote, onboarding }}
                  formRef={formRef}
                />
              )}
            </Stack>
            <Stack sx={{ paddingBottom: "30px" }}>
              <Form
                ref={formRef}
                schema={{
                  ...slide.schema(payload, slideState),
                }}
                uiSchema={{
                  ...uiSchema,
                  ...slide.uiSchema(payload, slideState),
                }}
                widgets={widgets}
                formData={payload}
                validator={validator}
                formContext={{
                  setPayload,
                  payload,
                  setSlideDirection,
                  slideState,
                  setSlideState,
                  slideIndex,
                  setError,
                  onboardingId: onboarding.id,
                }}
                onSubmit={({ formData }) => {
                  if (slide.customSubmit) {
                    return;
                  }
                  setPayload(formData);
                  setSlideDirection(1);
                  setError("");
                }}
                templates={{ ErrorListTemplate, FieldErrorTemplate }}
                transformErrors={(errors) => {
                  return errors.map((error) => {
                    if (error.name === "required") {
                      // error.message = <Alert severity="error" sx={{ width: "100%" }}>Required</Alert>;
                      error.message = "Required";
                    }
                    return error;
                  });
                }}
              >
                {Callout && (
                  <Callout
                    stepName={slide.key}
                    slideIndex={slideIndex}
                    payload={payload}
                    setPayload={setPayload}
                    data={{ job, home, quote, onboarding }}
                    formRef={formRef}
                  />
                )}
                {error && (
                  <Stack
                    spacing={2}
                    m={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Alert sx={{ margin: 2 }} severity="error">
                      {error}
                    </Alert>
                  </Stack>
                )}
                {!slide.customNavigation && (
                  <>
                    <Stack
                      spacing={2}
                      direction="row"
                      mt={2}
                      justifyContent="center"
                    >
                      {slideIndex > 0 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setSlideDirection(-1);
                          }}
                          sx={{
                            width: "150px",
                            color: slide.dark
                              ? theme.palette.primary.contrastText
                              : undefined,
                            borderColor: slide.dark ? "transparent" : undefined,
                            backgroundColor: slide.dark
                              ? "rgba(255,255,255,.2)"
                              : undefined,
                          }}
                        >
                          {slide.backTitle ? slide.backTitle : "Back"}
                        </Button>
                      )}
                      {slide.nextTitle !== "" && (
                        <Button
                          variant="contained"
                          type="submit"
                          data-cy="submit"
                          data-key={slide.key}
                          color="secondary"
                          sx={{ width: "150px" }}
                        >
                          {nextSlideIndex !== -1
                            ? slide.nextTitle
                              ? slide.nextTitle
                              : "Next"
                            : "Submit"}
                        </Button>
                      )}
                    </Stack>
                    {slide.canSkip && (
                      <Stack
                        width="100%"
                        justifyContent="center"
                        direction="row"
                        sx={{ marginTop: "10px" }}
                      >
                        <Button
                          variant="text"
                          onClick={() => setSkipModalOpen(true)}
                          color="secondary"
                          sx={{ textTransform: "none" }}
                        >
                          Skip this step
                        </Button>
                      </Stack>
                    )}
                  </>
                )}
                <SkipModal
                  open={skipModalOpen}
                  onClose={(toSkip: boolean) => handleSkip(toSkip)}
                />
                {Footer && (
                  <Footer
                    stepName={slide.key}
                    setPayload={setPayload}
                    formRef={formRef}
                    payload={payload}
                    slideIndex={slideIndex}
                    slideState={slideState}
                    setSlideState={setSlideState}
                    setError={setError}
                    error={error}
                    data={{ job, home, quote, onboarding }}
                    setSlideDirection={setSlideDirection}
                    setSlideIndex={setSlideIndex}
                    getSpecificSlide={getSpecificSlide}
                  />
                )}
              </Form>
            </Stack>
          </Box>
        </Slide>
      </div>
      <PreFetchImages />
    </OnboardingContext.Provider>
  );
};

export default OnboardingPageView;
