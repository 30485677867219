import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { Theme } from "@mui/material";
import logo from "../pearl/logo.png";

import { baseConfig } from "../config";
import { baseOverrides } from "../overrides";

const LIGHT_BRIGHT_BLUE = "#E8F4FC";

const config = merge({}, cloneDeep(baseConfig), {
  name: "Pearl Edison - Contractor Portal",
  language: {
    "en-us": {},
  },
  tiers: {
    default: "base",
    available: ["base", "pearl"],
  },
});

const overrides = merge({}, cloneDeep(baseOverrides), {
  palette: {
    secondary: {
      light: LIGHT_BRIGHT_BLUE,
    },
  },
  logo: {
    imageSrc: logo,
    alt: "Pearl Logo",
    width: 149,
    height: 40,
    poweredBy: false,
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none", // This removes the default divider
          },
          "&.Mui-expanded": {
            margin: 0, // This ensures no margin change when expanded
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 15,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: () => ({
          borderRadius: 5,
          color: "#FFFFFF",
          backgroundColor: "#591E90",
          "&.Mui-expanded": {
            minHeight: 50, // Maintain height when expanded
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          "& .MuiAccordionSummary-content": {
            margin: "12px 0",
            "&.Mui-expanded": {
              margin: "12px 0",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "#FFFFFF",
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          height: 1,
          width: "100%",
          backgroundColor: theme.palette.divider,
          margin: `${theme.spacing(1)} 0`,
        }),
      },
    },
  },
});

export default {
  ...overrides,
  config,
};
