/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

export const Progress = ({
  slides,
  slide,
  onChange,
  sx = { margin: 2 },
}: {
  slides: any[];
  slide: any;
  slideIndex: number;
  onChange: (slideIndex: number) => void;
  sx: any;
}) => {
  const currentKey = slide.progress;
  const keys = useMemo(() => {
    const _keys: string[] = [];
    slides.forEach((s) => {
      if (s.progress && !_keys.includes(s.progress)) {
        _keys.push(s.progress);
      }
    });
    return _keys;
  }, [slides]);
  const keyIndex = keys.findIndex((value) => value === slide.progress);
  if (!currentKey) return <></>;
  return (
    <Stack direction="row" spacing={2} sx={{ ...sx }}>
      {keys.map((key: string, index: number) => {
        return (
          <Stack key={key} direction="row" spacing={2}>
            <Typography
              sx={{
                color: index <= keyIndex ? "purple" : "initial",
                fontWeight: index == keyIndex ? "bold" : "initial",
                cursor: "pointer",
              }}
              onClick={() => {
                for (let i = 0; i < slides.length; i += 1) {
                  if (slides[i].progress === key) {
                    onChange(i);
                    break;
                  }
                }
              }}
            >
              {key}
            </Typography>
            {index < keys.length - 1 ? <Typography>-</Typography> : null}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Progress;
