import React, { useContext } from "react";
import { Button, Stack, Typography, useTheme } from "@mui/material";

import { SettingsContext } from "@library/settings/provider";
import { Quote } from "@library/domain/quote";

const EstimateNextSteps = ({
  // quote,
  // quoteId,
  setShowCalendly,
}: {
  quote: Quote;
  quoteId: string;
  setShowCalendly: (show: boolean) => void;
}) => {
  const { isAuthenticated } = useContext(SettingsContext);
  const theme = useTheme();
  return (
    <Stack flex={1.0} p={[0, 0, 2]} mt={[4, 4, 0]}>
      <Stack>
        <Typography
          color="secondary.main"
          fontSize="1.5rem"
          fontWeight="100"
          sx={{
            fontSize: "1.5rem",
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          Your Next Steps
        </Typography>
        <Typography
          variant="body1"
          align="left"
          fontSize="1.0rem"
          sx={{ marginTop: "5px" }}
        >
          Your next step is to speak with a Pearl Comfort Adviser. We&rsquo;ll
          confirm a few key details, revise your design based on your feedback,
          and provide you with a final quote.
        </Typography>
      </Stack>
      <Stack direction="column" mt={2} spacing={2}>
        <Stack
          width="50%"
          borderRadius="25px"
          margin={0}
          py={2}
          px={2}
          spacing={2}
          flex={1}
          color="primary.contrastText"
          sx={{
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <Typography
            color="primary.contrastText"
            variant="body1"
            mt={5}
            sx={{
              fontSize: "1.3rem",
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            Talk to a Pearl Advisor
          </Typography>
          <Typography
            variant="body1"
            align="left"
            sx={{
              fontSize: "1.0rem",
              fontWeight: theme.typography.fontWeightLight,
            }}
          >
            Schedule a home assessment or virtual consultation.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowCalendly(true)}
            sx={{
              borderRadius: "15px",
              backgroundColor: "rgba(255,255,255,0.2)",
              boxShadow: "none",
              fontSize: "0.8rem",
              textTransform: "none",
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {!isAuthenticated ? "Log In" : "Schedule Now"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EstimateNextSteps;
