import React from "react";
import { useTheme } from "@mui/material";

export const MuchSmaller: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H210C214.418 0 218 3.58172 218 8V152C218 156.418 214.418 160 210 160H8C3.58173 160 0 156.418 0 152V8Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M66.029 49.8419L67.1917 56.4363L109.167 49.0349L108.005 42.4404L66.029 49.8419Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M131.956 122V100H117.956V122"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <circle cx="121.956" cy="113" r="1" fill="white" />
      <path
        d="M105.823 113.978V96.9775H82.5981V113.978"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M92.5981 75V62H82.5981V75"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M92.5981 68.5L82.5981 68.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55 123H163"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.1365 88.5859H150.863V81.5747H67.1365"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.2242 56.3809L70.2242 81.5747"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.2242 88.5859L70.2242 123"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M147.776 88.5859L147.776 122.369"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M105.823 49.7466L105.823 81.5747"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M139.119 81.2136V71.0928"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M147.443 81.2136V71.0928"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M130.795 81.2136V71.0928"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.471 81.2136V71.0928"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M114.147 81.2136V71.0928"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M105.823 71.0928H147.373"
        style={{ stroke: theme.palette.secondary.contrastText }}
        stroke-width="1.5"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MuchSmaller;
