/* eslint-disable @typescript-eslint/no-explicit-any */

import onboardingSchema from "@library/common/schema";
import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import { Description, Title } from "../components";
const props = onboardingSchema.properties;

const baseSchema = {
  definitions: onboardingSchema.definitions,
  type: "object",
};

const baseUISchema = {
  "ui:ObjectFieldTemplate": ObjectFieldTemplate,
  "ui:autocomplete": "off",
  width: "100%",
  maxWidth: "900px",
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
};

export const sortingSlides = [
  {
    // 1
    key: "ProjectTypeStep",
    Title,
    Description,
    schema: () => {
      return {
        ...baseSchema,
        required: ["projectType"],
        properties: {
          projectType: {
            ...props.projectType,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        projectType: {
          "ui:widget": "RadioImages",
        },
      };
    },
    onNext: ({
      payload,
      setPayload,
    }: {
      payload: any;
      setPayload: (payload: any) => void;
    }) => {
      if (payload.projectType === "heating") {
        setPayload({
          ...payload,
          airConditioningProjectType: "",
        });
        return {
          slideKey: "HeatingProjectStep",
        };
      } else if (payload.projectType === "air_conditioning") {
        setPayload({
          ...payload,
          heatingProjectType: "",
        });
        return {
          slideKey: "AirConditioningProjectStep",
        };
      }
    },
  },
  // 2
  {
    key: "HeatingProjectStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (payload.projectType === "heating") return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["heatingProjectType"],
        properties: {
          heatingProjectType: {
            ...props.heatingProjectType,
          },
          heatingProjectTypeMoreInfo: {
            ...props.heatingProjectTypeMoreInfo,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        heatingProjectType: {
          stacked: true,
          "ui:widget": "RadioImages",
        },
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.heatingProjectType === "service") {
        return {
          slideKey: "HeatingOrAirConditioningServiceStep",
        };
      } else if (payload.heatingProjectType === "replace") {
        return {
          slideKey: "HeatingOrAirConditioningReplacementStep",
        };
      } else if (payload.heatingProjectType === "add") {
        return {
          slideKey: "LetsGetStartedStep",
        };
      }
    },
  },
  // 3
  {
    key: "AirConditioningProjectStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (payload.projectType === "air_conditioning") return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["airConditioningProjectType"],
        properties: {
          airConditioningProjectType: {
            ...props.airConditioningProjectType,
          },
          airConditioningProjectTypeMoreInfo: {
            ...props.airConditioningProjectTypeMoreInfo,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        airConditioningProjectType: {
          stacked: true,
          "ui:widget": "RadioImages",
        },
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.airConditioningProjectType === "service") {
        return {
          slideKey: "HeatingOrAirConditioningServiceStep",
        };
      } else if (payload.airConditioningProjectType === "replace") {
        return {
          slideKey: "HeatingOrAirConditioningReplacementStep",
        };
      } else if (payload.airConditioningProjectType === "add") {
        return {
          slideKey: "LetsGetStartedStep",
        };
      }
    },
  },
  // 4
  {
    key: "HeatingOrAirConditioningServiceStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "service" ||
          payload.airConditioningProjectType === "service")
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: [
              "firstName",
              "lastName",
              "email",
              "phoneNumber",
              "communicationPreference",
            ],
            type: "object",
            properties: {
              firstName: {
                ...props.firstName,
              },
              lastName: {
                ...props.lastName,
              },
              email: {
                ...props.email,
              },
              phoneNumber: {
                ...props.phoneNumber,
              },
              communicationPreference: {
                ...props.communicationPreference,
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          phoneNumber: {
            "ui:placeholder": "(XXX) XXX-XXXX",
            format: (value: string, previousValue: string) => {
              if (!value) return value;
              const currentValue = value.replace(/[^\d]/g, "");
              const cvLength = currentValue.length;
              if (!previousValue || value.length > previousValue.length) {
                if (cvLength < 4) return currentValue;
                if (cvLength < 7)
                  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
                return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
              }
            },
            margin: 0,
          },
          communicationPreference: {
            "ui:widget": "RadioImages",
            ...props.communicationPreference,
          },
        },
      };
    },
    onNext: () => {
      return {
        slideKey: "HeatingOrAirConditioningScheduleServiceStep",
      };
    },
  },
  // 5
  {
    key: "HeatingOrAirConditioningScheduleServiceStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "service" ||
          payload.airConditioningProjectType === "service")
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 6
  {
    key: "HeatingOrAirConditioningReplacementStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace")
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["projectMotivation"],
        properties: {
          projectMotivation: {
            ...props.projectMotivation,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        projectMotivation: {
          stacked: true,
          "ui:widget": "RadioImages",
        },
      };
    },
    onNext: ({ payload }: { payload: any }) => {
      if (payload.projectMotivation === "support") {
        return {
          slideKey: "HeatingOrAirConditioningReplaceEmergencyStep",
        };
      } else if (
        payload.projectMotivation === "online_quote" ||
        payload.projectMotivation === "directional_estimate"
      ) {
        return {
          slideKey: "HeatingOrAirConditioningWholeOrPartialStep",
        };
      } else if (payload.projectMotivation === "feedback") {
        return {
          slideKey: "UploadQuoteStep",
        };
      } else if (payload.projectMotivation === "incentives") {
        return {
          slideKey: "IncentivesAddressStep",
        };
      }
    },
  },
  // 7
  {
    key: "HeatingOrAirConditioningReplaceEmergencyStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        payload.projectMotivation === "support"
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: [
              "firstName",
              "lastName",
              "email",
              "phoneNumber",
              "communicationPreference",
            ],
            type: "object",
            properties: {
              firstName: {
                ...props.firstName,
              },
              lastName: {
                ...props.lastName,
              },
              email: {
                ...props.email,
              },
              phoneNumber: {
                ...props.phoneNumber,
              },
              communicationPreference: {
                ...props.communicationPreference,
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          phoneNumber: {
            "ui:placeholder": "(XXX) XXX-XXXX",
            format: (value: string, previousValue: string) => {
              if (!value) return value;
              const currentValue = value.replace(/[^\d]/g, "");
              const cvLength = currentValue.length;
              if (!previousValue || value.length > previousValue.length) {
                if (cvLength < 4) return currentValue;
                if (cvLength < 7)
                  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
                return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
              }
            },
            margin: 0,
          },
          communicationPreference: {
            "ui:widget": "RadioImages",
            ...props.communicationPreference,
          },
        },
      };
    },
    onNext: () => {
      return {
        slideKey: "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
      };
    },
  },
  // 8
  {
    key: "HeatingOrAirConditioningReplaceEmergencyScheduleStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        payload.projectMotivation === "support"
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 9
  {
    key: "HeatingOrAirConditioningWholeOrPartialStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.airConditioningProjectType === "replace") &&
        (payload.projectMotivation === "online_quote" ||
          payload.projectMotivation === "directional_estimate")
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        required: ["wholeOrPartialHome"],
        properties: {
          wholeOrPartialHome: {
            ...props.wholeOrPartialHome,
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        wholeOrPartialHome: {
          stacked: true,
          "ui:widget": "RadioImages",
        },
      };
    },
  },
  // 10, 11
  {
    key: "LetsGetStartedStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.heatingProjectType === "add" ||
          payload.airConditioningProjectType === "replace" ||
          payload.airConditioningProjectType === "add") &&
        (payload.projectMotivation === "online_quote" ||
          payload.projectMotivation === "directional_estimate")
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 12
  {
    key: "UploadQuoteStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.heatingProjectType === "add" ||
          payload.airConditioningProjectType === "replace" ||
          payload.airConditioningProjectType === "add") &&
        payload.projectMotivation === "feedback"
      )
        return true;
      return false;
    },
    schema: () => {},
    uiSchema: () => {},
  },
  // 12
  {
    key: "IncentivesAddressStep",
    Title,
    Description,
    visible: ({ payload }: { payload: any }) => {
      if (
        ["heating", "air_conditioning"].includes(payload.projectType) &&
        (payload.heatingProjectType === "replace" ||
          payload.heatingProjectType === "add" ||
          payload.airConditioningProjectType === "replace" ||
          payload.airConditioningProjectType === "add") &&
        payload.projectMotivation === "incentives"
      )
        return true;
      return false;
    },
    schema: () => {
      return {
        ...baseSchema,
        properties: {
          user: {
            required: ["address"],
            type: "object",
            properties: {
              address: {
                title: "Address",
                type: "string",
              },
            },
          },
        },
      };
    },
    uiSchema: () => {
      return {
        ...baseUISchema,
        user: {
          "ui:options": {
            label: false,
          },
          address: {
            "ui:widget": "AutocompleteAddress",
            "ui:autocomplete": "off",
            width: "100%",
          },
        },
      };
    },
  },
];

export default sortingSlides;
