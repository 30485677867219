import React from "react";
import { Box, Card, Grid, Typography, useTheme } from "@mui/material";

import Logos from "@library/components/Logos";

const brands = ["hisense", "amana", "mitsubishi"];

const BrandsWeLoveGrid = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxShadow: "none",
        border: `1px solid ${theme.palette.divider}`,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          padding: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          bgcolor: "background.paper",
        }}
      >
        <Typography color="primary.main" fontSize={16} fontWeight={400}>
          Brands we install:
        </Typography>
      </Box>
      <Grid container>
        {brands.map((brand, rowIndex) => (
          <Grid
            item
            xs={3}
            key={rowIndex}
            sx={{
              padding: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
              "& img": {
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              },
            }}
          >
            <Logos brand={brand} />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default BrandsWeLoveGrid;
