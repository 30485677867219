import React, { useEffect, useState } from "react";
import {
  Box,
  // Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  // Button,
  useTheme,
  Paper,
} from "@mui/material";
import throttle from "lodash/throttle";
import { useInView } from "react-intersection-observer";

import Icon1 from "@library/assets/icon1.svg";
import Icon2 from "@library/assets/icon2.svg";
import Icon3 from "@library/assets/icon3.svg";
// import Logo from "@library/theme/multitenancy/pearl/logo.png";
import { GetStartedStepQuote } from "@library/data/concierge";
import { Estimate } from "@library/domain/estimate";
import { ConciergeView } from "@library/components/Concierge";
import { Quote } from "@library/domain/quote";

interface StepDescriptionProps {
  title?: string;
  time?: string;
  description?: string;
}

interface RefObject<T> {
  readonly current: T;
}

const StepDescription: React.FC<StepDescriptionProps> = ({
  title,
  time,
  description,
}) => (
  <Stack color="primary.contrastText" spacing={1}>
    <Stack direction="row" spacing={3} alignItems="center">
      <Typography
        color="warning.main"
        flex={1}
        sx={{ fontSize: { xs: "16px", sm: "14px", md: "17px", lg: "20px" } }}
      >
        {title}
      </Typography>
      <Typography fontSize="14px">{time}</Typography>
    </Stack>
    <Typography color="primary.contrastText" fontSize="14px">
      {description}
    </Typography>
  </Stack>
);

const LineDivider = () => (
  <Divider
    sx={{ bgcolor: "grey.600", opacity: 0.5, margin: "16px 0 16px 0" }}
  />
);
interface GetStartedInfoProps {
  headerInViewRef: null | undefined | RefObject<HTMLElement>;
  buttonInViewRef: null | undefined | RefObject<HTMLButtonElement>;
}

const GetStartedInfo: React.FC<GetStartedInfoProps> = ({
  headerInViewRef,
  // buttonInViewRef,
}) => {
  const theme = useTheme();
  return (
    <>
      <Stack direction="row" spacing={2} mb={2}>
        <img src={Icon1} alt="icon1" width={"50px"} />
        <img src={Icon2} alt="icon2" width={"50px"} />
        <img src={Icon3} alt="icon3" width={"50px"} />
      </Stack>
      <Typography
        sx={{ fontSize: { xs: "24px" } }}
        color="primary.contrastText"
        fontWeight={"bold"}
        ref={headerInViewRef}
      >
        {theme.t(`step.GettingStartedStep.title`)}
      </Typography>
      <Typography
        sx={{ fontSize: { xs: "16px", sm: "14px", md: "14px", lg: "20px" } }}
        color="primary.contrastText"
      >
        {theme.t(`step.GettingStartedStep.description`)}
      </Typography>

      <LineDivider />
      <StepDescription
        title={theme.t(`step.GettingStartedStep.step1Title`)}
        time={theme.t(`step.GettingStartedStep.step1Time`)}
        description={theme.t(`step.GettingStartedStep.step1Description`)}
      />
      <LineDivider />
      <StepDescription
        title={theme.t(`step.GettingStartedStep.step2Title`)}
        time={theme.t(`step.GettingStartedStep.step2Time`)}
        description={theme.t(`step.GettingStartedStep.step2Description`)}
      />
      <LineDivider />
      <StepDescription
        title={theme.t(`step.GettingStartedStep.step3Title`)}
        time={theme.t(`step.GettingStartedStep.step3Time`)}
        description={theme.t(`step.GettingStartedStep.step3Description`)}
      />
    </>
  );
};

export const StaticComponent = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  // const [sessionReplay, setSessionReplay] = useState(false);
  const [headerInViewRef, isHeaderFullyInView] = useInView({
    threshold: 1,
  });
  const [buttonInViewRef, isButtonFullyInView] = useInView({
    threshold: 1,
  });
  const [override, setOverride] = useState<boolean>(false);
  const _headerInViewRef =
    headerInViewRef as unknown as RefObject<HTMLButtonElement>;
  const _buttonInViewRef =
    buttonInViewRef as unknown as RefObject<HTMLButtonElement>;

  useEffect(() => {
    // if (!sessionReplay) {
    //   const client = Sentry.getClient();
    //   if (!client) {
    //     return;
    //   }
    //   try {
    //     client.addIntegration(
    //       Sentry.replayIntegration({
    //         maskAllText: false,
    //         blockAllMedia: false,
    //       }),
    //     );
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    if (!override && isHeaderFullyInView && !isButtonFullyInView) {
      setOverride(true);
    }

    const resetCheckInView = () => {
      setOverride(false);
    };

    const throttleResetCheckInView = throttle(resetCheckInView, 2000, {
      trailing: true,
    });
    window.addEventListener("resize", throttleResetCheckInView);
    return () => {
      window.removeEventListener("resize", throttleResetCheckInView);
    };
  }, [
    // sessionReplay,
    // setSessionReplay,
    setOverride,
    override,
    isHeaderFullyInView,
    isButtonFullyInView,
  ]);

  const estimates = GetStartedStepQuote.Estimate as unknown as Estimate[];

  let zoom = "0.5";
  if (isMedium && isSmall) {
    zoom = "0.25";
  } else if (isMedium) {
    zoom = "0.40";
  }
  const quote = {
    id: GetStartedStepQuote.id,
    Estimate: estimates,
  } as unknown as Quote;

  return (
    <Stack bgcolor="primary.main" spacing={5} px={10}>
      {/* <Stack
        justifyContent="center"
        position={"fixed"}
        alignSelf="center"
        alignItems={"center"}
        height="auto"
        sx={{
          zIndex: 2,
          backgroundColor: "primary.main",
          width: "100%",
        }}
      >
        <Stack sx={{ padding: "10px 0px" }}>
          <img src={Logo} alt="Logo" />
        </Stack>
      </Stack> */}
      <Grid container width="100%">
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ width: "100%" }}>
            <GetStartedInfo
              buttonInViewRef={_buttonInViewRef}
              headerInViewRef={_headerInViewRef}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{
            overflow: "hidden",
            display: { xs: "none", sm: "none", md: "block" },
          }}
        >
          <Paper
            sx={{
              pointerEvents: "none",
              borderRadius: "10px",
              bgColor: "red",
              overflowY: "scroll",
              height: "500px",
              marginLeft: 10,
            }}
          >
            <ConciergeView
              zoom={zoom}
              filter={"blur(0.40rem)"}
              borderRadius={"10px"}
              user={{ ...GetStartedStepQuote.user }}
              matches={true}
              quote={quote}
              selectedTier={theme.config.tiers?.default ?? "base"}
              setSelectedEstimate={() => {}}
              quoteId={GetStartedStepQuote.id!}
              setError={() => {}}
              setWarning={() => {}}
              setShowCalendly={() => {}}
            />
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StaticComponent;
