import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  Theme,
  Alert,
} from "@mui/material";

import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";
import BrandsWeLoveGrid from "@library/components/Concierge/BrandsWeInstallGrid";
import { formatCurrency, formatPricingData } from "@library/common";
import OptionsDialog from "../OptionsDialog";
import api from "@library/api";
import { QuoteContext } from "@library/components/Concierge";

const baseLanguageLocation = "concierge.PackageDetails.ScopeContent";

const otherOptions = [
  "fasterInstallation",
  "customizeSystem",
  "brandPreference",
];

type DialogVariant =
  | "fasterInstallation"
  | "customizeSystem"
  | "brandPreference";

type CustomizeOptionKey = "waterHeater" | "airQuality" | "insulation" | "other";

interface DialogContentProps {
  theme?: Theme;
}

const FasterInstallationContent: React.FC<
  DialogContentProps & {
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
  }
> = ({ theme, phoneNumber, setPhoneNumber }) => (
  <>
    <Typography variant="h6">
      {theme?.t(
        `${baseLanguageLocation}.options.fasterInstallation.dialogSubtitle`
      )}
    </Typography>
    <Typography variant="body1" sx={{ mb: 2 }}>
      {theme?.t(
        `${baseLanguageLocation}.options.fasterInstallation.dialogText`
      )}
    </Typography>
    <TextField
      fullWidth
      label="Immediate Contact Number"
      placeholder="000-000-0000"
      sx={{ mb: 2 }}
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
    />
  </>
);

interface BrandPreferenceContentProps extends DialogContentProps {
  value: string;
  onChange: (value: string) => void;
}

const BrandPreferenceContent: React.FC<BrandPreferenceContentProps> = ({
  theme,
  value,
  onChange,
}) => (
  <>
    <Typography variant="h6" sx={{ mb: 2 }}>
      {theme?.t(
        `${baseLanguageLocation}.options.brandPreference.dialogSubtitle`
      )}
    </Typography>
    <TextField
      fullWidth
      multiline
      rows={4}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter your message..."
      sx={{ mb: 2 }}
    />
    <Typography variant="body2">
      {theme?.t(`${baseLanguageLocation}.options.brandPreference.dialogFooter`)}
    </Typography>
  </>
);

const CustomizeSystemContent: React.FC<{
  theme?: Theme;
  warning?: string;
  customizeOptions: Record<CustomizeOptionKey, boolean>;
  setCustomizeOptions: (value: Record<CustomizeOptionKey, boolean>) => void;
}> = ({ theme, warning, customizeOptions, setCustomizeOptions }) => {
  // Define the options we want to show, matching the language file structure
  const options: CustomizeOptionKey[] = [
    "waterHeater",
    "airQuality",
    "insulation",
    "other",
  ];

  return (
    <>
      {warning && <Alert severity="error">{warning}</Alert>}
      <Typography variant="body1" sx={{ mb: 2 }}>
        {theme?.t(
          `${baseLanguageLocation}.options.customizeSystem.description`
        )}
      </Typography>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            sx={{ mb: 1 }}
            control={
              <Checkbox
                checked={customizeOptions[option]}
                onChange={(event) => {
                  setCustomizeOptions({
                    ...customizeOptions,
                    [option]: event.target.checked,
                  });
                }}
              />
            }
            label={
              <Box>
                <Typography variant="body1">
                  {theme?.t(
                    `${baseLanguageLocation}.options.customizeSystem.options.${option}.title`
                  )}
                </Typography>
                {option !== "other" && (
                  <>
                    <Typography variant="body2">
                      {theme?.t(
                        `${baseLanguageLocation}.options.customizeSystem.options.${option}.description`
                      )}
                    </Typography>
                    <Link href="#" variant="body2">
                      {theme?.t(`${baseLanguageLocation}.learnMore`)}
                    </Link>
                  </>
                )}
              </Box>
            }
          />
        ))}
      </FormGroup>
      {warning && (
        <Typography color="error" variant="body2">
          {warning}
        </Typography>
      )}
    </>
  );
};

export const ScopeContent: React.FC<SectionContentProps> = ({
  package: pkg,
  theme,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogVariant, setDialogVariant] = useState<DialogVariant | null>(
    null
  );
  const [warning, setWarning] = useState("");
  const [brandPreferences, setBrandPreferences] = useState("");
  const [customizeOptions, setCustomizeOptions] = useState<
    Record<CustomizeOptionKey, boolean>
  >({
    waterHeater: false,
    airQuality: false,
    insulation: false,
    other: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const { quote, quoteId, refresh } = useContext(QuoteContext);

  // Initialize from quote when available
  useEffect(() => {
    if (quote?.Onboarding?.data?.user?.phoneNumber) {
      setPhoneNumber(quote.Onboarding.data.user.phoneNumber);
    }
  }, [quote?.Onboarding?.data?.user?.phoneNumber]);

  // Initialize state from quote.userInput
  useEffect(() => {
    if (quote?.userInput) {
      // Set brand preferences if they exist
      if (quote.userInput.brandPreferences) {
        setBrandPreferences(quote.userInput.brandPreferences);
      }

      // Set customize options if they exist
      const savedCustomizeOptions = {
        waterHeater: false,
        airQuality: false,
        insulation: false,
        other: false,
        ...quote.userInput, // This will override the defaults with saved values
      };

      setCustomizeOptions(savedCustomizeOptions);
    }
  }, [quote?.userInput]);

  const updateQuoteUserInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (updates: Record<string, any>) => {
      try {
        await api.put("quote", { userInput: updates }, { id: quoteId });
        return true;
      } catch (error) {
        setWarning(
          "A backend error occurred while carrying out your last request."
        );
        return false;
      }
    },
    [quoteId]
  );

  const formattedSections = formatPricingData(pkg?.polyvectorPayload);

  const handleOptionClick = (variant: DialogVariant) => {
    setDialogVariant(variant);
    setIsDialogOpen(true);
  };

  const toggleDialog = () => {
    setIsDialogOpen(false);
    setDialogVariant(null);
    setWarning(""); // Only reset warning
  };

  const handleDialogClose = async () => {
    if (dialogVariant === "customizeSystem") {
      await updateQuoteUserInput(customizeOptions);
    } else if (dialogVariant === "brandPreference" && brandPreferences) {
      await updateQuoteUserInput({ brandPreferences });
    } else if (dialogVariant === "fasterInstallation" && phoneNumber) {
      try {
        // Include notify: true when updating phone number from
        // dialog to alert admins of faster installation request
        await api.put(`user/${quote?.userId}`, {
          phoneNumber,
          notify: true,
        });

        // Get the current onboarding data and merge with the new phone number
        const payload = {
          ...quote?.Onboarding?.data,
          user: {
            ...quote?.Onboarding?.data?.user,
            phoneNumber,
          },
        };

        await api.put(`onboarding/${quote?.Onboarding?.id}`, {
          payload,
        });

        if (refresh) {
          await refresh();
        }
      } catch (error) {
        setWarning("Failed to update phone number");
        return;
      }
    }

    if (refresh) {
      await refresh();
    }

    toggleDialog();
  };

  const getDialogContent = () => {
    switch (dialogVariant) {
      case "fasterInstallation":
        return (
          <FasterInstallationContent
            theme={theme}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        );
      case "customizeSystem":
        return (
          <CustomizeSystemContent
            theme={theme}
            warning={warning}
            customizeOptions={customizeOptions}
            setCustomizeOptions={setCustomizeOptions}
          />
        );
      case "brandPreference":
        return (
          <BrandPreferenceContent
            theme={theme}
            value={brandPreferences}
            onChange={setBrandPreferences}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Root>
      <Box>
        <Typography className={classes.sectionContentDescription}>
          {theme?.t(`${baseLanguageLocation}.description`)}
        </Typography>
        <Typography
          variant={"h5"}
          className={classes.sectionContentSubtitle}
          sx={{ mt: 2, mb: 1 }}
        >
          {pkg?.tier
            ? pkg.tier.charAt(0).toUpperCase() + pkg.tier.slice(1)
            : ""}{" "}
          {theme?.t(`${baseLanguageLocation}.scope`)}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {formattedSections.map((section, index) => (
              <React.Fragment key={`${section.title}-${index}`}>
                <Box className={classes.contentLineItem}>
                  <Typography className={classes.boldText}>
                    {section.title}
                  </Typography>
                  <Typography>{formatCurrency(section.total)}</Typography>
                </Box>
                <Divider sx={{ mb: 2, mt: 1 }} />
                <ul className={classes.taskListContainer}>
                  {section.items.map((item, idx) => (
                    <li key={`${item.id}-${idx}`}>
                      <Typography variant="body2" className={classes.boldText}>
                        {item.name}
                      </Typography>
                      {item.note && (
                        <Typography variant="body2">{item.note}</Typography>
                      )}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
            <Divider sx={{ mb: 2 }} />
            <Box className={classes.contentLineItem}>
              <Typography className={classes.boldText}>
                {theme?.t(`${baseLanguageLocation}.total`)}
              </Typography>
              <Typography>
                {formatCurrency(pkg?.polyvectorPayload?.installedCostTotal)}
              </Typography>
            </Box>
            <Box className={classes.learnMoreBox} sx={{ mb: 2 }}>
              {theme?.t(`${baseLanguageLocation}.installationNote`)}{" "}
              <a href="#">{theme?.t(`${baseLanguageLocation}.learnMore`)}</a>
            </Box>
            <BrandsWeLoveGrid />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography sx={{ mb: 1 }} className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.otherOptions`)}
            </Typography>
            {otherOptions.map((option: string) => (
              <Box key={option} className={classes.otherOptionContainer}>
                <Typography variant="h6">
                  {theme?.t(`${baseLanguageLocation}.options.${option}.title`)}
                </Typography>
                <Typography variant="body2">
                  {theme?.t(
                    `${baseLanguageLocation}.options.${option}.description`
                  )}
                </Typography>
                <Box className={classes.otherOptionButtonContainer}>
                  <Button
                    className={classes.otherOptionButton}
                    onClick={() => handleOptionClick(option as DialogVariant)}
                  >
                    {theme?.t(`${baseLanguageLocation}.options.request`)}
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
      <OptionsDialog
        open={isDialogOpen}
        toggle={toggleDialog}
        title={theme?.t(
          `${baseLanguageLocation}.options.${dialogVariant}.title`
        )}
        buttonText={theme?.t(
          `${baseLanguageLocation}.options.${dialogVariant}.dialogButtonText`
        )}
        onClick={handleDialogClose}
      >
        {getDialogContent()}
      </OptionsDialog>
    </Root>
  );
};
