import { Dialog, styled } from "@mui/material";

const PREFIX = "OptionsDialog";

export const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },
}));
