import { styled } from "@mui/material";

const PREFIX = "ConciergePageView";

export const classes = {
  contentContainer: `${PREFIX}-contentContainer`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleText: `${PREFIX}-titleText`,
  userProfileButton: `${PREFIX}-userProfileButton`,
  avatarAndNameContainer: `${PREFIX}-avatarAndNameContainer`,
  advisorName: `${PREFIX}-advisorName`,
  advisorContactInfoContainer: `${PREFIX}-advisorContactInfoContainer`,
  advisorContactInfoRow: `${PREFIX}-advisorContactInfoRow`,
  advisorContactInfo: `${PREFIX}-advisorContactInfo`,
  footerContainer: `${PREFIX}-footerContainer`,
  footerButton: `${PREFIX}-footerButton`,
  swiperNavRowContainer: `${PREFIX}-swiperNavRowContainer`,
  swiperNavContainer: `${PREFIX}-swiperNavContainer`,
  swiperNavButton: `${PREFIX}-swiperNavButton`,
  bottomBorderContainer: `${PREFIX}-bottomBorderContainer`,
  impactArrowIcon: `${PREFIX}-impactArrowIcon`,
  boldText: `${PREFIX}-boldText`,
  lightWeightText: `${PREFIX}-  lightWeightText`,
  lightIcon: `${PREFIX}-lightIcon`,
  recommendedStrongText: `${PREFIX}-recommendedStrongText`,
  recommendedPackageText: `${PREFIX}-recommendedPackageText`,
  cardFooterContent: `${PREFIX}-cardFooterContent`,
  cardFooterViewingText: `${PREFIX}-cardFooterViewingText`,
  cardFooterButton: `${PREFIX}-cardFooterButton`,
  packageContainer: `${PREFIX}-packageContainer`,
  selectedPackageContainer: `${PREFIX}-selectedPackageContainer`,
  selectedPackageArrow: `${PREFIX}-selectedPackageArrow`,
  packageDetailsContainer: `${PREFIX}-packageDetailsContainer`,
  packageDetailsTitleContainer: `${PREFIX}-packageDetailsTitleContainer`,
  packageDetailsTitle: `${PREFIX}-packageDetailsTitle`,
  packageDetailsSummary: `${PREFIX}-packageDetailsSummary`,
  verticalNavContainer: `${PREFIX}-verticalNavContainer`,
  verticalNavButton: `${PREFIX}-verticalNavButton`,
  verticalNavButtonActive: `${PREFIX}-verticalNavButtonActive`,
  mobileFooterContainer: `${PREFIX}-mobileFooterContainer`,
  mobileFooterIcons: `${PREFIX}-mobileFooterIcons`,
  mobileFooterButton: `${PREFIX}-mobileFooterButton`,
  mobileFooterButtonContent: `${PREFIX}-mobileFooterButtonContent`,
  mobileAdvisorDetails: `${PREFIX}-mobileAdvisorDetails`,
  advisorInfo: `${PREFIX}-advisorInfo`,
  contactRow: `${PREFIX}-contactRow`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.contentContainer}`]: {
    minHeight: "100vh",
    marginTop: "0 !important",
    padding: theme.spacing(3),
  },
  [`& .${classes.loadingContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  [`& .${classes.titleContainer}`]: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  [`& .${classes.titleText}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.swiperNavRowContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.swiperNavContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.swiperNavButton}`]: {
    borderRadius: 5,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.bottomBorderContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.impactArrowIcon}`]: {
    marginBottom: -6,
  },
  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },
  [`& .${classes.lightWeightText}`]: {
    fontWeight: 300,
  },
  [`& .${classes.lightIcon}`]: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: `-${theme.spacing(1)}`,
    color: theme.palette.primary.light,
  },
  [`& .${classes.avatarAndNameContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.advisorName}`]: {
    marginTop: -5,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  [`& .${classes.advisorContactInfoContainer}`]: {
    marginLeft: 1,
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  [`& .${classes.advisorContactInfoRow}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      fontSize: 14,
    },
    "& svg": {
      fontSize: 14,
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.advisorContactInfo}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  [`& .${classes.userProfileButton}`]: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    textTransform: "none",
    justifyContent: "space-between",
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  [`& .${classes.footerContainer}`]: {
    zIndex: 2,
    width: "100%",
    bottom: 0,
    position: "fixed",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: 60,
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      height: 100,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  },
  [`& .${classes.recommendedStrongText}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.recommendedPackageText}`]: {
    width: 200,
    borderRadius: 10,
    textAlign: "center",
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${classes.cardFooterContent}`]: {
    width: "100%",
    textAlign: "center",
  },
  [`& .${classes.cardFooterViewingText}`]: {
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  [`& .${classes.cardFooterButton}`]: {
    width: "100%",
    borderRadius: 5,
    padding: theme.spacing(1.5),
  },
  [`& .${classes.packageContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    boxShadow: "none",
    borderRadius: "8px",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    height: "100%",
  },
  [`& .${classes.selectedPackageContainer}`]: {
    border: `3px solid ${theme.palette.primary.main} !important`,
  },
  [`& .${classes.selectedPackageArrow}`]: {
    position: "absolute",
    bottom: -20,
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: `20px solid ${theme.palette.primary.main}`,
    zIndex: 9999,
  },
  [`& .${classes.footerButton}`]: {
    height: 40,
    fontSize: 18,
    fontWeight: 400,
    borderRadius: 5,
    textOverflow: "ellipsis",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  [`& .${classes.packageDetailsContainer}`]: {
    borderRadius: 5,
    marginBottom: 100, // Account for footer
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.packageDetailsTitleContainer}`]: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.packageDetailsTitle}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${classes.packageDetailsSummary}`]: {
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  [`& .${classes.verticalNavContainer}`]: {
    display: "flex",
    position: "sticky",
    gap: theme.spacing(1),
    top: theme.spacing(10),
    flexDirection: "column",
  },

  [`& .${classes.verticalNavButton}`]: {
    justifyContent: "flex-start",
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  [`& .${classes.verticalNavButtonActive}`]: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light + "20", // 20 is for opacity
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.light + "30",
    },
  },
  [`& .${classes.mobileFooterContainer}`]: {
    width: "100%",
  },
  [`& .${classes.mobileFooterIcons}`]: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  [`& .${classes.mobileFooterButton}`]: {
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  [`& .${classes.mobileFooterButtonContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  [`& .${classes.mobileAdvisorDetails}`]: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.common.white}`,
  },
  [`& .${classes.advisorInfo}`]: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  [`& .${classes.contactRow}`]: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.common.white,
    "& svg": {
      fontSize: 18,
    },
    "& Typography": {
      fontSize: 14,
    },
  },
  [`& .swiper-slide`]: {
    height: "auto",
    display: "flex",
    "& > div": {
      // The Box wrapper around the Card
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  [`& .swiper`]: {
    paddingBottom: "20px", // Add padding to the bottom of the swiper
  },

  [`& .swiper-wrapper`]: {
    height: "auto !important", // Override any fixed height
  },
}));
