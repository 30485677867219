import React from "react";
import { Root, classes } from "./styles";
import { Alert, Snackbar } from "@mui/material";

interface ErrorToastProps {
  text: string;
  onClose: () => void;
}

const ErrorToast: React.FC<ErrorToastProps> = ({ onClose, text = "" }) => {
  return (
    <Root>
      <Snackbar
        open={!!text}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={onClose}
          severity="error"
          className={classes.errorAlert}
        >
          {text}
        </Alert>
      </Snackbar>
    </Root>
  );
};

export default ErrorToast;
