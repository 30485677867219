import { Theme } from "@mui/material";

export const background = (theme: Theme) => {
  return theme.palette.primary.main;
};

export const visible = ({ theme }: { theme: Theme }) => {
  if (!theme.t(`step.GettingStartedStep.title`)) {
    return false;
  }
  return true;
};
