export const defaultAdvisor = {
  name: "Brynn C.",
  image: "/path-to-advisor-image.jpg",
  phone: "000-000-0000",
  email: "advisor@pearledison.com",
};

export const equipmentDummyData = {
  heating: {
    system: {
      brand: "Amana",
      series: "S-Series",
      description: "Example system that meets these technical specifications",
      imageUrl: "/placeholder.jpg",
      load: "48,000 BTU",
    },
    primary: {
      type: "Air-Source Heat Pump",
      capacity: "48,000 BTU",
      SEER2: "Min 18.0",
      EER2: "Min 10.1",
      HSPF2: "Min 9.5",
    },
    backup: {
      type: "Air-Source Heat Pump",
      capacity: "80,000 BTU",
      AFUE: "Min 96%",
    },
  },
  cooling: {
    system: {
      brand: "Amana",
      series: "S-Series",
      description: "Example system that meets these technical specifications",
      imageUrl: "/placeholder.jpg",
      load: "48,000 BTU",
    },
    primary: {
      type: "Air-Source Heat Pump",
      capacity: "48,000 BTU",
      SEER2: "Min 18.0",
      EER2: "Min 10.1",
      HSPF2: "Min 9.5",
    },
  },
};

{
  /* For bill and energy impact graph dropdowns */
}
export const weatherOptions = ["Mild", "Average", "Extreme"];

export const energyUsageOptions = [
  "More than normal",
  "Status Quo",
  "Less than normal",
];

export const energyPricesOptions = [
  "Expensive Natural Gas",
  "Status Quo",
  "Expensive Electricity",
];
