import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  Avatar,
  Grid,
  IconButton,
  useTheme,
  Theme,
  Collapse,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Dialog,
} from "@mui/material";

import isNumber from "lodash/isNumber";

import {
  ChevronLeft,
  ChevronRight,
  Phone as PhoneIcon,
  Email as EmailIcon,
  CalendarToday as CalendarTodayIcon,
  VideoCall as VideoCallIcon,
  Info as InfoIcon,
} from "@mui/icons-material";

import {
  ConciergePageViewProps,
  Package,
  PackageCardProps,
  PackageCarouselProps,
  PackageDetailsProps,
  PackageDetailsSection,
  ScheduleFooterProps,
} from "./types";

import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Root, classes } from "./styles";
import { SettingsContext } from "@library/settings/provider";
import { defaultAdvisor } from "./constants";

import {
  SummaryContent,
  ScopeContent,
  PricingContent,
  BillImpactContent,
  EnergyImpactContent,
  LoadCalculationContent,
  EquipmentContent,
  WhyPearlEdisonContent,
  TestimonialContent,
  FAQContent,
} from "./components/sections";
// import SchedulingDialog from "@library/components/SchedulingDialog"; // FYI: commenting out and relying on calendly integration for now
import { InlineWidget } from "react-calendly";
import { useParams } from "react-router-dom";
import api from "@library/api";
import { Job } from "@library/domain/job";
import { Quote } from "@library/domain/quote";
import ErrorToast from "@library/components/ErrorToast";
import { formatCurrency, formatNumber } from "@library/common";
import {
  CALENDLY_URL_HOME_ASSESSMENT,
  CALENDLY_URL_HOME_CONSULT,
} from "@library/components/Concierge/CalendlyModal";
import { QuoteContext } from "@library/components/Concierge";

const getSections = (
  selectedPackage: Package,
  theme: Theme
): PackageDetailsSection[] => [
  {
    label: "Summary",
    value: "summary",
    content: <SummaryContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Scope of Work",
    value: "scope",
    content: <ScopeContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Pricing, Rebates & Financing",
    value: "pricing",
    content: <PricingContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Bill Impact",
    value: "bill",
    content: <BillImpactContent package={selectedPackage} />,
  },
  {
    label: "Energy Savings Impact",
    value: "energy",
    content: <EnergyImpactContent package={selectedPackage} />,
  },
  {
    label: "Load Calculation",
    value: "loadCalc",
    content: <LoadCalculationContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Equipment & Tech Specs",
    value: "equipment",
    content: <EquipmentContent package={selectedPackage} theme={theme} />,
  },

  {
    label: "Why Pearl Edison?",
    value: "whyPearl",
    content: <WhyPearlEdisonContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "Testimonials",
    value: "testimonials",
    content: <TestimonialContent package={selectedPackage} theme={theme} />,
  },
  {
    label: "FAQs",
    value: "faqs",
    content: <FAQContent package={selectedPackage} />,
  },
  // ... other sections
];

const PackageCard: React.FC<PackageCardProps> = ({
  package: pkg,
  onSelect,
}) => {
  return (
    <Box position="relative">
      {" "}
      {/* Wrapper to contain both card and arrow */}
      <Card
        className={
          pkg.isSelected
            ? `${classes.selectedPackageContainer} ${classes.packageContainer}`
            : classes.packageContainer
        }
      >
        <Typography variant="h4" color={pkg.isSelected ? "primary" : "inherit"}>
          {pkg.tier ? pkg.tier.charAt(0).toUpperCase() + pkg.tier.slice(1) : ""}{" "}
          Package
        </Typography>
        {pkg.tier === "pearl" && (
          <Typography
            variant="subtitle2"
            className={classes.recommendedPackageText}
          >
            Recommended for you
          </Typography>
        )}
        <Typography variant="body2" sx={{ mt: 2 }}>
          {/* {pkg.data?.recommendationExplanation} */}
          {pkg.data?.recommendation}
        </Typography>

        <Box sx={{ mt: 3, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              Pricing Options
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography variant="body2">Single Payment:</Typography>
            <Typography className={classes.lightWeightText}>
              {formatCurrency(pkg.data?.upFrontCostTotal)}
            </Typography>
          </Box>
          <Typography variant="body2">
            Finance for as little as {/* TODO: replace with real financing */}
            <strong
              className={
                pkg.tier === "pearl" ? classes.recommendedStrongText : ""
              }
            >
              $TBD/month
            </strong>{" "}
            over 10 years, with $0 down.
          </Typography>
        </Box>

        <Box sx={{ mt: 2, width: "100%" }}>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              Annual Bill Impact:
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.data?.billImpactEstimate)
                ? formatCurrency(pkg?.data?.billImpactEstimate, {
                    increaseDecrease: true,
                    empty: true,
                  }) || "$0"
                : "TBD"}
              <Tooltip title={pkg?.data?.billImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
          <Box className={classes.bottomBorderContainer}>
            <Typography className={classes.boldText}>
              Climate Impact:
            </Typography>
            <Typography className={classes.lightWeightText}>
              {isNumber(pkg?.data?.climateImpactEstimate)
                ? formatNumber(pkg?.data?.climateImpactEstimate, {
                    units: " MT",
                    increaseDecrease: true,
                  }) || "0 MT"
                : "TBD"}
              <Tooltip title={pkg?.data?.climateImpactExplanation}>
                <InfoIcon className={classes.lightIcon} />
              </Tooltip>
            </Typography>
          </Box>
        </Box>

        <Box className={classes.cardFooterContent}>
          {pkg.isSelected ? (
            <Typography
              className={`${classes.cardFooterViewingText} ${classes.lightWeightText}`}
            >
              Currently Viewing this package
            </Typography>
          ) : (
            <Button
              fullWidth
              variant={"outlined"}
              sx={{ mt: 2 }}
              onClick={onSelect}
              className={classes.cardFooterButton}
            >
              Learn More
            </Button>
          )}
        </Box>
      </Card>
      {pkg.isSelected && <div className={classes.selectedPackageArrow} />}
    </Box>
  );
};

const SwiperCarousel: React.FC<PackageCarouselProps> = ({
  packages,
  selectedIndex,
  onSelectPackage,
  theme,
  job,
}) => {
  const sliderRef = useRef<SwiperRef>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const slidesPerView = 3;

  const getVisibleRange = () => {
    const start = activeIndex + 1;
    const end = Math.min(activeIndex + slidesPerView, packages.length);
    return `${start}-${end}`;
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef?.current?.swiper.slideNext();
  }, []);

  return (
    <Box>
      <Box className={classes.swiperNavRowContainer}>
        <Typography variant="body2">
          {theme.t(`concierge.SwiperCarousel.title`)}{" "}
          {job?.Home?.address1 || "Unknown"}:
        </Typography>
        <Box className={classes.swiperNavContainer}>
          <Typography variant="body2">
            {getVisibleRange()} of {packages.length}
          </Typography>
          <IconButton className={classes.swiperNavButton} onClick={handlePrev}>
            <ChevronLeft />
          </IconButton>
          <IconButton className={classes.swiperNavButton} onClick={handleNext}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Swiper
          rewind={true}
          ref={sliderRef}
          modules={[A11y]}
          spaceBetween={15}
          slidesPerView={3}
          slidesPerGroup={1}
          centeredSlides={false}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          breakpoints={{
            // adjust as needed
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
              slidesPerGroup: 1,
            },
          }}
        >
          {packages.map((pkg: Package, index: number) => (
            <SwiperSlide key={`${pkg.tier}-${index}`}>
              <PackageCard
                package={{
                  ...pkg,
                  isSelected: index === selectedIndex,
                }}
                onSelect={() => onSelectPackage(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

const ScheduleFooter: React.FC<ScheduleFooterProps> = ({
  advisor,
  onScheduleAssessment,
  onScheduleConsultation,
  theme,
}) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [showAdvisorDetails, setShowAdvisorDetails] = useState(false);

  const MobileFooter = () => (
    <Box className={classes.mobileFooterContainer}>
      <Box className={classes.mobileFooterIcons}>
        <IconButton
          onClick={onScheduleAssessment}
          className={classes.mobileFooterButton}
        >
          <Box className={classes.mobileFooterButtonContent}>
            <CalendarTodayIcon />
            <Typography variant="caption">Assessment</Typography>
          </Box>
        </IconButton>

        <IconButton
          onClick={onScheduleConsultation}
          className={classes.mobileFooterButton}
        >
          <Box className={classes.mobileFooterButtonContent}>
            <VideoCallIcon />
            <Typography variant="caption">Consultation</Typography>
          </Box>
        </IconButton>

        <IconButton
          onClick={() => setShowAdvisorDetails(!showAdvisorDetails)}
          className={classes.mobileFooterButton}
        >
          <Box className={classes.mobileFooterButtonContent}>
            <Avatar
              alt="Advisor"
              src={""} // TODO: where does this image come from
              sx={{ width: 24, height: 24 }}
            />
            <Typography variant="caption">Advisor</Typography>
          </Box>
        </IconButton>
      </Box>

      <Collapse in={showAdvisorDetails}>
        <Box className={classes.mobileAdvisorDetails}>
          <Box className={classes.advisorInfo}>
            <Typography variant="subtitle1">{advisor?.name}</Typography>
            <Box className={classes.advisorContactInfo}>
              <Box className={classes.contactRow}>
                <PhoneIcon fontSize="small" />
                <Typography variant="body2">000-000-0000</Typography>
              </Box>
              <Box className={classes.contactRow}>
                <EmailIcon fontSize="small" />
                <Typography variant="body2">advisor@pearledison.com</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );

  const DesktopFooter = () => (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Box style={{ width: "100%" }}>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.finalizeButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleAssessment}
          >
            {theme.t(`concierge.ScheduleFooter.finalizeButtonLabel`)}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box>
          <Typography sx={{ mb: 1 }}>
            {theme.t(`concierge.ScheduleFooter.consultationButtonDescription`)}
          </Typography>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            className={classes.footerButton}
            onClick={onScheduleConsultation}
          >
            {theme.t(`concierge.ScheduleFooter.consultationButtonLabel`)}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Button className={classes.userProfileButton}>
          <Box className={classes.avatarAndNameContainer}>
            <Avatar
              alt="Profile picture"
              src={""} // TODO: where does this image come from
              sx={{ width: 40, height: 40 }}
            />
            <Box sx={{ ml: 1, textAlign: "left" }}>
              <Typography variant="body2">
                {theme.t(`concierge.ScheduleFooter.yourAdvisor`)}
              </Typography>
              <Typography variant="h5" className={classes.advisorName}>
                {advisor?.name}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.advisorContactInfoContainer}>
            <Box className={classes.advisorContactInfoRow}>
              <PhoneIcon />
              <Typography>000-000-0000</Typography>
            </Box>
            <Box className={classes.advisorContactInfoRow}>
              <EmailIcon />
              <Typography>advisor@pearledison.com</Typography>
            </Box>
          </Box>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.footerContainer}>
      {isMobileOrTablet ? <MobileFooter /> : <DesktopFooter />}
    </Box>
  );
};

const PackageDetails: React.FC<PackageDetailsProps> = ({
  selectedPackage,
  selectedSection,
  onSectionChange,
}) => {
  const theme = useTheme();

  const sections = getSections(selectedPackage, theme);
  const activeSection =
    sections.find((section) => section.value === selectedSection) ||
    sections[0];

  return (
    <Box className={classes.packageDetailsContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box>
            <Box className={classes.packageDetailsTitleContainer}>
              <Typography className={classes.packageDetailsTitle}>
                {selectedPackage?.tier
                  ? selectedPackage.tier.charAt(0).toUpperCase() +
                    selectedPackage.tier.slice(1)
                  : ""}
              </Typography>
              <Typography
                variant="h4"
                className={classes.packageDetailsSummary}
              >
                {activeSection.label}
              </Typography>
            </Box>
            {activeSection.content}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.verticalNavContainer}>
            {sections.map((section) => (
              <Button
                key={section.value}
                fullWidth
                className={`${classes.verticalNavButton} ${
                  selectedSection === section.value
                    ? classes.verticalNavButtonActive
                    : ""
                }`}
                onClick={() => onSectionChange(section.value)}
              >
                {section.label}
              </Button>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ConciergePageViewV3: React.FC<ConciergePageViewProps> = () => {
  const { jobId, quoteId = "" } = useParams();
  const { user } = useContext(SettingsContext);
  const [advisor] = useState(defaultAdvisor);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(1); // Default to Pearl Package
  const [selectedSection, setSelectedSection] = useState("summary");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [quote, setQuote] = useState<Quote | null>(null);
  const [job, setJob] = useState<Job | null>(null);
  const [isScheduleAssessmentDialogOpen, setIsScheduleAssessmentDialogOpen] =
    useState(false);
  const [
    isScheduleConsultationDialogOpen,
    setisScheduleConsultationDialogOpen,
  ] = useState(false);

  const theme = useTheme();

  const fetchConciergeData = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await api.get("concierge", {
        jobId: jobId,
        quoteId: quoteId,
      });
      if (response.success) {
        setJob(response.data.job);
        setQuote(response.data.quote);
      } else {
        setError("Failed to fetch jobs");
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("An error occurred while fetching jobs");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = useCallback(async () => {
    await fetchConciergeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use refresh for initial data fetch
  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleSectionChange = (section: string) => {
    setSelectedSection(section);
  };

  const toggleScheduleAssessmentDialog = () => {
    setIsScheduleAssessmentDialogOpen(!isScheduleAssessmentDialogOpen);
  };

  const toggleScheduleConsultationDialog = () => {
    setisScheduleConsultationDialogOpen(!isScheduleConsultationDialogOpen);
  };

  const handlePackageSelect = (index: number) => {
    setSelectedPackageIndex(index);
  };

  return (
    <Root>
      <QuoteContext.Provider
        value={{ quoteId: quoteId, quote: quote as Quote, refresh }}
      >
        <Box className={classes.titleContainer}>
          <Typography variant="h4" align="center" className={classes.titleText}>
            {user?.firstName || "Hello"}
            {isLoading
              ? theme.t(`concierge.loadingTitle`)
              : theme.t(`concierge.title`)}
          </Typography>

          <Typography align="center" variant="body2">
            {theme.t(`concierge.description`)}
          </Typography>

          <ErrorToast onClose={() => setError("")} text={error} />
        </Box>

        {isLoading ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className={classes.contentContainer}>
            <SwiperCarousel
              job={job}
              theme={theme}
              packages={quote?.Estimate?.filter((e) => e.viewable) || []}
              selectedIndex={selectedPackageIndex}
              onSelectPackage={handlePackageSelect}
            />

            <PackageDetails
              selectedPackage={quote?.Estimate?.[selectedPackageIndex] || {}}
              selectedSection={selectedSection}
              onSectionChange={handleSectionChange}
            />
          </Box>
        )}
        <ScheduleFooter
          theme={theme}
          advisor={advisor}
          onScheduleAssessment={toggleScheduleAssessmentDialog}
          onScheduleConsultation={toggleScheduleConsultationDialog}
        />
        {/* FYI: commenting out and relying on calendly integration for now */}
        {/* <SchedulingDialog
        type="assessment"
        open={isScheduleAssessmentDialogOpen}
        onClose={toggleScheduleAssessmentDialog}
      />
      <SchedulingDialog
        type="consultation"
        open={isScheduleConsultationDialogOpen}
        onClose={toggleScheduleConsultationDialog}
      /> */}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleAssessmentDialogOpen}
          onClose={toggleScheduleAssessmentDialog}
        >
          <InlineWidget url={CALENDLY_URL_HOME_ASSESSMENT} />
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={isScheduleConsultationDialogOpen}
          onClose={toggleScheduleConsultationDialog}
        >
          <InlineWidget url={CALENDLY_URL_HOME_CONSULT} />
        </Dialog>
      </QuoteContext.Provider>
    </Root>
  );
};

export default ConciergePageViewV3;
