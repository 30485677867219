import React from "react";
import { Typography, Box, Grid, Card, Divider } from "@mui/material";

import { Root, classes } from "./styles";
import { SectionContentProps } from "../../types";

const selfFinanceLineItems = [
  { name: "Due at home assessment", value: "0" },
  { name: "Due at signing", value: "3,400" },
  { name: "Due after installation", value: "3,400" },
];

const loanLineItems = [
  { name: "Down payment", value: "0" },
  { name: "APR", value: "%8.0" },
  { name: "Month loan term", value: "120" },
];

const nowRebates = [
  { name: "Michigan Home Energy Rebate", value: "14,000" },
  { name: "A2ZERO Home Energy Rebate", value: "2,000" },
  { name: "Pearl Edison Discount", value: "500" },
];

const laterRebates = [{ name: "DTE Energy Heat Pump Rebate", value: "800" }];

const taxCredits = [{ name: "25C Federal Tax Credit", value: "1,800" }];

const baseLanguageLocation = "concierge.PackageDetails.PricingContent";

export const PricingContent: React.FC<SectionContentProps> = ({ theme }) => (
  <Root>
    <Box>
      <Typography className={classes.sectionContentDescription}>
        We searched for every applicable discount, rebate, and tax incentive to
        lower your price. You're eligible for <strong>at least $16,500</strong>,
        and you could be elible for <strong>as much as $22,600</strong> - we'll
        verify this during your home assessment. The details are below.
      </Typography>
      <Typography
        variant={"h5"}
        className={classes.sectionContentSubtitle}
        sx={{ mt: 2, mb: 1 }}
      >
        {theme?.t(`${baseLanguageLocation}.subtitle`)}:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.lightPurpleCard}>
            <Box
              className={`${classes.outlinedLightBorderBox} ${classes.contentLineItem}`}
            >
              <Typography className={classes.boldText}>
                {theme?.t(`${baseLanguageLocation}.beforeRebates`)}
              </Typography>
              <Typography className={classes.boldText}>$23,300</Typography>
            </Box>
            <Box className={classes.rebateDetailContainer}>
              <Box className={classes.contentLineItem}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.rebates.payNow.title`)}
                </Typography>
                <Typography>-$16,500</Typography>
              </Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {theme?.t(`${baseLanguageLocation}.rebates.payNow.description`)}
              </Typography>
              {nowRebates.map((rebate) => (
                <Box className={classes.contentLineItem} sx={{ mb: 1 }}>
                  <Typography variant="body2">{rebate.name}</Typography>
                  <Typography variant="body2">-${rebate.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box
              className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
            >
              <Typography className={classes.boldText}>
                {theme?.t(`${baseLanguageLocation}.totalPayment`)}
              </Typography>
              <Typography className={classes.boldText}>$6,800</Typography>
            </Box>
            <Box className={classes.rebateDetailContainer}>
              <Box className={classes.contentLineItem}>
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.rebates.payLater.title`)}
                </Typography>
                <Typography>-$800</Typography>
              </Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {theme?.t(
                  `${baseLanguageLocation}.rebates.payLater.description`
                )}
              </Typography>
              {laterRebates.map((rebate) => (
                <Box className={classes.contentLineItem} sx={{ mb: 1 }}>
                  <Typography variant="body2">{rebate.name}</Typography>
                  <Typography variant="body2">-${rebate.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box
              className={`${classes.outlinedLightBorderBox} ${classes.contentLineItem}`}
            >
              <Typography className={classes.boldText}>
                {theme?.t(`${baseLanguageLocation}.afterRebates`)}
              </Typography>
              <Typography className={classes.boldText}>$6,000</Typography>
            </Box>
            <Box className={classes.rebateDetailContainer}>
              <Box className={classes.contentLineItem}>
                <Typography className={classes.boldText}>
                  Tax Credits
                  {theme?.t(`${baseLanguageLocation}.rebates.taxCredits.title`)}
                </Typography>
                <Typography>-$1,800</Typography>
              </Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {theme?.t(
                  `${baseLanguageLocation}.rebates.taxCredits.description`
                )}
              </Typography>
              {taxCredits.map((rebate) => (
                <Box className={classes.contentLineItem} sx={{ mb: 1 }}>
                  <Typography variant="body2">{rebate.name}</Typography>
                  <Typography variant="body2">-${rebate.value}</Typography>
                </Box>
              ))}
            </Box>
            <Box
              className={`${classes.outlinedLightBorderBox} ${classes.contentLineItem}`}
            >
              <Typography className={classes.boldText}>
                {theme?.t(`${baseLanguageLocation}.afterTaxCredits`)}
              </Typography>
              <Typography className={classes.boldText}>$4,200</Typography>
            </Box>
          </Card>
          <Card className={classes.lightPurpleCard} sx={{ mt: 2 }}>
            <Typography className={classes.boldText}>
              {theme?.t(`${baseLanguageLocation}.rebates.additional.title`)}
            </Typography>
            <Typography variant="body2">
              You could be eligible for up to $3,500 in additional rebates via
              the A2ZERO Home Energy Rebate program. We will evaluate your
              eligibility during your home assessment.
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Typography
              sx={{ p: 2 }}
              variant={"h5"}
              color="primary.light"
              className={classes.largeCardTitle}
            >
              {theme?.t(`${baseLanguageLocation}.paymentOptions.subtitle`)}
            </Typography>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography className={classes.paymentOptionTitle} sx={{ mb: 1 }}>
                {theme?.t(
                  `${baseLanguageLocation}.paymentOptions.options.first.title`
                )}
              </Typography>
              {selfFinanceLineItems.map((lineItem) => (
                <Box className={classes.contentLineItem} sx={{ mb: 1 }}>
                  <Typography variant="body2">{lineItem.name}</Typography>
                  <Typography variant="body2">-${lineItem.value}</Typography>
                </Box>
              ))}
              <Box
                className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.totalPayment`)}
                </Typography>
                <Typography className={classes.boldText}>$6,800</Typography>
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography className={classes.paymentOptionTitle} sx={{ mb: 1 }}>
                {theme?.t(
                  `${baseLanguageLocation}.paymentOptions.options.second.title`
                )}
              </Typography>
              {loanLineItems.map((lineItem) => (
                <Box className={classes.contentLineItem} sx={{ mb: 1 }}>
                  <Typography variant="body2">{lineItem.name}</Typography>
                  <Typography variant="body2">{lineItem.value}</Typography>
                </Box>
              ))}
              <Box
                className={`${classes.filledDarkBox} ${classes.contentLineItem}`}
              >
                <Typography className={classes.boldText}>
                  {theme?.t(`${baseLanguageLocation}.totalPayment`)}
                </Typography>
                <Typography className={classes.boldText}>$83/month</Typography>
              </Box>
              {/* TODO: Add Michigan Saves logo */}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Root>
);
