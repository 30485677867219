import { useState, useRef, useEffect } from "react";
import toNumber from "lodash/toNumber";
import { Onboarding, OnboardingPayload } from "@library/domain/onboarding";
import {
  FloorData,
  LoadCalcRoom,
  RoomData,
  SourceTotals,
} from "@library/domain/home";
import { PolyvectorPayload, PricingLineItem } from "@library/domain/estimate";

export const PHONE_NUMBER = "(313) 334-7525";
export const PHONE_NUMBER_TEL = "1 313 334 7525";
export const EMAIL = "hello@pearledison.com";
export const PHONE_NUMBER_JAKE = "1 312 358 5085";

let CORP_HOST = "";
if (import.meta.env.ENV === "prod") {
  CORP_HOST = "https://pearledison.com";
} else if (import.meta.env.ENV === "staging") {
  CORP_HOST = "https://preview.pearledison.com";
} else {
  CORP_HOST = "";
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MSStream: any;
  }
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};

export const corpUrl = (path: string) => `${CORP_HOST}${path}`;

let CUSTOMER_HOST = "http://localhost:5173";
if (import.meta.env.VITE_ENV === "staging") {
  CUSTOMER_HOST = "https://preview.pearledison.com";
} else if (import.meta.env.VITE_ENV === "prod") {
  CUSTOMER_HOST = "https://app.pearledison.com";
}

export const customerUrl = (path: string) => `${CUSTOMER_HOST}${path}`;

export const useIsTruncated = () => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, []);

  return [ref, isTruncated] as const;
};

// custom hook for dynamic displaying when text is truncated
export const useIsHorizontallyTruncated = (content: React.ReactNode) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const checkTruncation = () => {
      const element = ref.current;
      if (element) {
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [content]);

  return [ref, isTruncated] as const;
};

export const shortenFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  const lastLetter = lastName?.charAt(0).toUpperCase();

  const name = `${firstName} ${lastLetter}`;
  return name;
};

// Handle UPPERCASE_WITH_UNDERSCORES format
export const formatFromUpperCase = (name: string = ""): string => {
  return name
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

interface FormatNumberOpts {
  round?: boolean;
  empty?: boolean;
  sign?: boolean;
  toFixed?: number;
  showNegative?: boolean;
  increaseDecrease?: boolean;
  units?: string;
}

export const formatNumber = (
  value: number | string | null | undefined,
  opts?: FormatNumberOpts
) => {
  if (opts?.empty && !value) return "";
  // if (!value) return new Intl.NumberFormat().format(0);

  let newValue = value;
  if (typeof newValue === "string") {
    newValue = newValue.replace(/,/g, "");
  }
  newValue = toNumber(newValue);

  if (opts?.round) {
    newValue = Math.round(newValue);
  }

  let formatted = new Intl.NumberFormat("en", {
    minimumFractionDigits: opts?.toFixed ?? 0,
  }).format(newValue);

  if (opts?.units) {
    formatted = `${formatted}${opts.units}`;
  }

  if (opts?.increaseDecrease) {
    if (value) {
      if (formatted.includes("-")) {
        formatted = formatted.replace(/-/g, "");
        formatted = `${formatted} decrease`;
      } else {
        formatted = `${formatted} increase`;
      }
    }
  }

  if (opts?.showNegative === false) {
    formatted = formatted.replace(/-/g, "");
  }

  return formatted;
};

const defaultOpts = { round: true, sign: true, empty: false };

export const formatCurrency = (
  value: number | string | null | undefined,
  opts: FormatNumberOpts = { round: true, sign: true, empty: false }
) => {
  if (opts?.empty && !value) return "";
  const fullOpts = { ...defaultOpts, ...opts };
  return `${fullOpts.sign ? "$" : ""}${formatNumber(value, fullOpts)}`;
};

export const camelCaseToHuman = (camelCaseString: string): string => {
  let result = camelCaseString.replace(/_/g, " ");
  result = result.replace(/([A-Z])/g, " $1").toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const chunksToGrid = (chunks: number = 3) => {
  if (!chunks) return undefined;
  if (chunks == 1) return 12;
  if (chunks == 2) return 6;
  if (chunks == 3) return 4;
  if (chunks == 4) return 3;
  if (chunks == 5) return 2.4;
  if (chunks == 6) return 2;
  if (chunks == 7) return 1.714;
  if (chunks == 8) return 1.5;
  return 4;
};

export const operationalStates = ["MI"];

export const JOB_STATUS = {
  10: "Onboarding",
  20: "Instant Estimate",
  30: "Home Assessment",
  40: "Final Quote",
  50: "Contracted",
  60: "Scheduled",
  70: "In-Progress",
  80: "Complete",
  100: "Closed-Win",
};

export const JOB_STATUS_OPTIONS = () => {
  return Object.keys(JOB_STATUS).map((id) => {
    return {
      value: parseInt(id, 10),
      title: JOB_STATUS[id as unknown as keyof typeof JOB_STATUS],
    };
  });
};

export const TIERS = {
  free: "Free",
  base: "Base",
  replace: "Replace",
  pearl: "Pearl",
  edison: "Edison",
  a2zero: "A²ZERO",
  a2zero_he: "A²Z Efficient",
};

export const camelToHuman = (str: string) => {
  let output = "";
  const len = str?.length || 0;
  let char;

  for (let i = 0; i < len; i++) {
    char = str.charAt(i);

    if (i == 0) {
      output += char.toUpperCase();
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += " " + char;
    } else if (char == "-" || char == "_") {
      output += " ";
    } else {
      output += char;
    }
  }

  return output;
};

export const getOnboardingData = (
  onboarding?: Onboarding
): Partial<OnboardingPayload> => {
  if (!onboarding) return {};

  if (onboarding.data) {
    return onboarding.data;
  } else if (onboarding.dataJSON) {
    try {
      const parsedData = JSON.parse(onboarding.dataJSON);

      // FYI this is probably a moot return, since `data` is defined
      return {
        sqFootage: parsedData.sqFootage,
        homeType: parsedData.homeType,
        yearBuilt: parsedData.yearBuilt,
        whatsImportantToYou: parsedData.whatsImportantToYou,
        peopleLiveInHome: parsedData.peopleLiveInHome,
        numBathrooms: parsedData.numBathrooms,
        numBedrooms: parsedData.numBedrooms,
        attic: parsedData.attic,
        atticFinish: parsedData.atticFinish,
        basement: parsedData.basement,
        basementFinished: parsedData.basementFinished,
        rooms: parsedData.rooms,
        user: {
          ...parsedData.user,
          phoneNumber: parsedData.user.phoneNumber,
          communicationPreference: parsedData.user.communicationPreference,
        },
      };
    } catch (error) {
      console.error("Error parsing dataJSON:", error);
      return {};
    }
  }

  return {};
};

export const calculateTotalBTU = (
  rooms: LoadCalcRoom[]
): {
  heating: number;
  cooling: number;
} => {
  const initialValue = { heating: 0, cooling: 0 };

  if (!rooms) return initialValue;

  return rooms.reduce((total, room) => {
    const coolingSum = room?.cooling
      ? Object.values(room.cooling).reduce((sum, value) => sum + value, 0)
      : 0;
    const heatingSum = room?.heating
      ? Object.values(room.heating).reduce((sum, value) => sum + value, 0)
      : 0;

    return {
      cooling: total.cooling + Math.ceil(coolingSum),
      heating: total.heating + Math.ceil(heatingSum),
    };
  }, initialValue);
};

export const calculateRoomBTU = (rooms: LoadCalcRoom[]): FloorData[] => {
  const floorMap = new Map<string, RoomData[]>();

  rooms.forEach((room) => {
    const floorName = room.floor;
    const roomType = room.type
      .replace("_", " ")
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());

    const coolingSum = room.cooling
      ? Object.values(room.cooling).reduce((sum, value) => sum + value, 0)
      : 0;
    const heatingSum = room.heating
      ? Object.values(room.heating).reduce((sum, value) => sum + value, 0)
      : 0;

    const roomData: RoomData = {
      name: roomType,
      sqFt: 0, // TODO: where does this come from?
      heatBTU: Math.ceil(heatingSum),
      coolBTU: Math.ceil(coolingSum),
    };

    if (!floorMap.has(floorName)) {
      floorMap.set(floorName, []);
    }
    floorMap.get(floorName)!.push(roomData);
  });

  const sortedFloors = Array.from(floorMap.entries()).sort(([a], [b]) => {
    if (a === "basement") return -1;
    if (b === "basement") return 1;
    return parseInt(a) - parseInt(b);
  });

  return sortedFloors.map(([floorName, rooms]) => ({
    name: floorName === "basement" ? "Basement" : `Floor ${floorName}`,
    rooms,
  }));
};

export const calculateSourceTotals = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadCalculations: any[]
): SourceTotals => {
  const initialTotals: SourceTotals = {
    heating: {
      wall: 0,
      window: 0,
      shg: 0,
      ceiling: 0,
      floor: 0,
      infiltration: 0,
      duct_loss: 0,
    },
    cooling: {
      wall: 0,
      window: 0,
      shg: 0,
      ceiling: 0,
      floor: 0,
      infiltration: 0,
      duct_loss: 0,
    },
  };

  if (!loadCalculations) return initialTotals;

  return loadCalculations.reduce((totals, room) => {
    ["heating", "cooling"].forEach((type) => {
      Object.keys(room[type]).forEach((source) => {
        totals[type][source] += room[type][source];
      });
    });
    return totals;
  }, initialTotals);
};

interface FormattedSection {
  title: string;
  total: number;
  items: PricingLineItem[];
}

interface CategoryMapping {
  title: string;
  categories: readonly string[];
  totalKey:
    | "hvacCostTotal"
    | "electricalCostTotal"
    | "weatherizationCostTotal"
    | "otherCostTotal";
}

const CATEGORY_MAPPINGS: Record<string, CategoryMapping> = {
  HVAC: {
    title: "HVAC System",
    categories: [
      "Indoor Unit",
      "Outdoor Unit",
      "Balance of System",
      "Permitting",
    ],
    totalKey: "hvacCostTotal",
  },
  ELECTRICAL: {
    title: "Electrical Work",
    categories: ["Electrical Wiring", "Service Panel"],
    totalKey: "electricalCostTotal",
  },
  WEATHERIZATION: {
    title: "Weatherization",
    categories: ["Weatherization"],
    totalKey: "weatherizationCostTotal",
  },
  OTHER: {
    title: "Other Costs",
    categories: ["Miscellaneous"],
    totalKey: "otherCostTotal",
  },
} as const;

export const formatPricingData = (
  payload?: PolyvectorPayload
): FormattedSection[] => {
  if (!payload) return [];

  const selectedTier = payload.pricingSelectedTier;
  const lineItems = payload.pricingLineItems?.[selectedTier] || [];
  const sections: FormattedSection[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(CATEGORY_MAPPINGS).forEach(([_, mapping]) => {
    const relevantItems = lineItems.filter((item: PricingLineItem) =>
      mapping.categories.includes(item.category as string)
    );

    if (relevantItems.length > 0) {
      const total = (payload[mapping.totalKey] as number) || 0;

      sections.push({
        title: mapping.title,
        total,
        items: relevantItems,
      });
    }
  });

  return sections;
};
